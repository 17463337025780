import * as React from 'react';
import { useEffect } from 'react';
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles"
import { StyledInput } from "./CustomUI";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material"
import { RegistrationDialogButton } from './CustomUI';
import { t } from "i18next";
import { context, ACTION_TYPE } from "../State";
import { tryLocalStorageGetItem, log, tryLocalStorageSetItem } from '../helpers/utils';
import Api from '../helpers/service';


const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        display: 'flex',
        width: 432,
        padding: 56,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 32,
        borderRadius: 16,
        border: '1px solid',
        borderColor: '#F0F5FF',
        background: '#FFFFFF',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)'
    },
    infoSpan: {
        alignSelf: 'stretch',
        color: '#000000',
        fontFamily: 'Source Sans Pro',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.09px',
        whiteSpace: 'pre-wrap'
    },
    emailSpan: {
        alignSelf: 'stretch',
        color: '#293DC7',
        fontFamily: 'Source Sans Pro',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '0.18px'
    },
    userDetailsDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 16,
        alignSelf: 'stretch'
    },
    formControl: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 4,
        alignSelf: 'stretch'
    }

}));

export default function CompleteRegistrationDialog(props) {
    
    const {open} = props;
    const classes = useStyles();
    const { dispatch } = React.useContext(context);
    const email = tryLocalStorageGetItem("email", "");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [companyNameError, setCompanyNameError] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const api = new Api();

    function handleClick() {
        setFirstNameError(firstName === "");
        setLastNameError(lastName === "");
        setCompanyNameError(companyName === "");

        if (firstNameError || lastNameError || companyNameError || !checked) {
            return;
        } else {
            api.createSalesForce(email, firstName, lastName, companyName).then(function (res) {
                log(res.status);
                setChecked(false);
                setFirstName("");
                setLastName("");
                setCompanyName("");
                dispatch({ type: ACTION_TYPE.OPEN_DIALOG_COMPLETE_REGISTRATION, payload: false });
                dispatch({type: ACTION_TYPE.REGISTRATION_FORM_FILLED, payload: true});
            }).catch(function (res) {
                log(res.status);
                sendEmail();
                setChecked(false);
                setFirstName("");
                setLastName("");
                setCompanyName("");
                dispatch({ type: ACTION_TYPE.OPEN_DIALOG_COMPLETE_REGISTRATION, payload: false });
                dispatch({type: ACTION_TYPE.REGISTRATION_FORM_FILLED, payload: true});
            });
        }
        tryLocalStorageSetItem("registrationFormFilled", JSON.stringify({ "user": email, "filled": true }));
        window.location.reload();
    }

    function sendEmail() {
            api.sendMailToSales(true, firstName, lastName, companyName);
    }

    function onChangeFirstName(event) {
        setFirstName(event.target.value);
        let firstNameTmp = event.target.value;
        setFirstNameError(firstNameTmp === "");
    } 

    function onChangeLastName(event) {
        setLastName(event.target.value);
        let lastNameTmp = event.target.value;
        setLastNameError(lastNameTmp === "");
    } 

    function onChangeCompanyName(event) {
        setCompanyName(event.target.value);
        let companyNameTmp = event.target.value;
        setCompanyNameError(companyNameTmp === "");
    } 

    return (
        <Dialog open={open} 
                componentsProps= {{backdrop: { style: {backgroundColor: '#050849', opacity: 0.9}} }}
                PaperProps={{style : {
                    display: 'flex',
                    width: 432,
                    padding: 56,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 32,
                    borderRadius: 16,
                    border: '1px solid',
                    borderColor: '#F0F5FF',
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)'
                },}}>
                <div>
                    <span className={classes.infoSpan}>
                        {t('dialog.complete_registration_intro')}
                    </span>
                    <span className={classes.emailSpan}> {email} </span>
                </div>
                <span className={classes.infoSpan}> {t('dialog.complete_registration_add_details')} </span>
                <div className={classes.userDetailsDiv}>
                <FormControl variant="standard" className={classes.formControl} >
                    <InputLabel style={{ color: firstNameError ? "#EB0000" : "#7E7E7E" }} shrink htmlFor="first-name">
                        {t('dialog.complete_registration_first_name_lbl')}
                    </InputLabel>
                    <StyledInput id="first-name" style={{ width: '100%' }} onChange={onChangeFirstName}  />
                </FormControl>
                <FormControl variant="standard" className={classes.formControl} >
                    <InputLabel style={{ color: lastNameError ? "#EB0000" : "#7E7E7E" }} shrink htmlFor="last-name">
                    {t('dialog.complete_registration_last_name_lbl')}
                    </InputLabel>
                    <StyledInput id="last-name" style={{ width: '100%'}} onChange={onChangeLastName} />
                </FormControl>
                <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel style={{ color: companyNameError ? "#EB0000" : "#7E7E7E" }} shrink htmlFor="company-name">
                    {t('dialog.complete_registration_company_name_lbl')}
                    </InputLabel>
                    <StyledInput id="company-name" style={{ width: '100%'}} onChange={onChangeCompanyName} />
                </FormControl>
                    <FormControlLabel className={classes.infoSpan} labelPlacement="end" control={<Checkbox sx={{marginTop: '-95px', color: '#293DC7'}} checked={checked} onChange={e => setChecked(e.target.checked)}  />} 
                                label={<span>{t('dialog.complete_registration_privacy_policy_info')} <a href="https://www.getidee.com/privacy-policy">{t('registration.checkbox.privacy_policy')}</a></span>} />
                </div>
                <RegistrationDialogButton onClick={handleClick}> {t('add_integration.continue')} </RegistrationDialogButton>
        </Dialog >
    );
}