


import * as React from "react"



export default function LogoutComp  (fields) {
    const {size} = fields;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="currentColor" class="bi bi-box-arrow-right" viewBox="3 0 19 16"> 
    <path fill-rule="evenodd" 
    d="M10 12.5 a.5.5 0 0 1-.5.5 h-8 a.5.5 0 0 1-.5-.5 v-9 a.5.5 0 0 1 .5-.5 h8 a.5.5 0 0 1 .5.5 v2 a.5.5 0 0 0 1 0 v-2 A1.5 1.5 0 0 0 9.5 2 h-8 A1.5 1.5 0 0 0 0 3.5 v9 A1.5 1.5 0 0 0 1.5 14 h8 a1.5 1.5 0 0 0 1.5-1.5 v-2 a.5.5 0 0 0-1 0 v2 z"/> 
    <path fill-rule="evenodd" 
    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/> 
</svg>)
  } 

