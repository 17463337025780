import { Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/styles";

const Check = styled(CheckIcon)(({theme}) => ({
    color: '#0BC65A',
    height: 14,
    width: 14,
    marginBottom: -2
}));

const Close = styled(CloseIcon)(({theme}) => ({
    color: '#C72941',
    height: 14,
    width: 14,
    marginBottom: -2
}));

export default function StatusText(props) {
    const {text, isError } = props;

    return(
        <>
        {!isError &&
            <Typography variant="statusTextView" component="span">
                <span style={{visibility: text === "" ? 'hidden' : 'visible'}}>
                    <Check/>
                    {text}
                </span>
            </Typography>
        }
        {isError &&
            <Typography variant="statusErrorTextView" component="span">
                <span style={{visibility: text === "" ? 'hidden' : 'visible'}}>
                    <Close/>
                    {text}
                </span>
            </Typography>
        }
        </>
    );
}