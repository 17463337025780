import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DateTimePickerCustom(props) {
  const {disabled, setValidFromTime} = props;
  //initial value is random date-time because it is never used
  const [value, setValue] = React.useState(dayjs.tz('2022-04-17T15:30'));

  function setTime(time) {
    setValue(time);
    let pickedTime = new Date(time);
    setValidFromTime(pickedTime.getTime());
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker 
          ampm = {false}
          disabled = {disabled}
          onChange = {value => setTime(value)}
          closeOnSelect = {false}
          sx = {{
            '& input': {
              visibility: 'hidden',
              width: 0,
              border: 'transparent'
            },
            '& label': {
              visibility: 'hidden'
            },
            '& fieldset': {
              borderColor: 'transparent'
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                border: 'hidden'
              }
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
                'fieldset': {
                  border: 'hidden'}
            },

          }}/>
      </DemoContainer>
    </LocalizationProvider>
  );
}