import * as React from 'react';
import Typography from "@mui/material/Typography/Typography";
import fileIcon from '../img/file.svg';
import { useTranslation } from "react-i18next";


export default function DownloadComponent(props) {
    const { infoDescription, filePath, fileName, isLinkComponent } = props;
    const { t } = useTranslation();

    const copyFile = () => {
        fetch(filePath)
            .then(response => response.text())
            .then((data) => {
                navigator.clipboard.writeText(data);
            })
    }

    return (
        (isLinkComponent === undefined || isLinkComponent === false) ?
            <div id="downloadComponent">
                <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', color: 'black' }}>
                    {infoDescription.label}
                </Typography>
                <div style={{ marginTop: '20px' }}>
                    <img src={fileIcon} style={{ width: '30px', height: '30px', display: 'inline-block' }} />
                    <div style={{ display: 'inline-block', marginLeft: '18px', transform: 'translateY(-25%)', font: 'normal normal normal 14px/24px Source Sans Pro', color: "#A0A0A0" }}>{fileName}</div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <a href={filePath} style={{ font: 'normal normal 600 12px/17px Source Sans Pro', letterSpacing: '0px', color: '#293DC7' }}>{t('add_integration.download_file')}</a>
                    <a style={{ font: 'normal normal 600 12px/17px Source Sans Pro', letterSpacing: '0px', color: '#293DC7', marginLeft: '18px', cursor: 'pointer', textDecoration: 'underline' }} onClick={copyFile}>{t('add_integration.copy_content')}</a>
                </div>
            </div>
            :
            <a href={filePath} style={{ font: 'normal normal normal 14px/24px Source Sans Pro', letterSpacing: '0px', color: '#293DC7' }}>{fileName}</a>

    )
}
