import React, {createContext, useReducer} from "react";
import {tryLocalStorageGetItem, tryLocalStorageSetItem} from "./helpers/utils";

export const ACTION_TYPE = {
    AUTH_USER : 'AUTH_USER',
    SETUP_SUCCESS: 'SETUP_SUCCESS',
    OPEN_DIALOG_INVITE_NEW_USER: 'OPEN_DIALOG_INVITE_NEW_USER',
    OPEN_DIALOG_ADD_ADMIN: 'OPEN_DIALOG_ADD_ADMIN',
    USER_DATA:'USER_DATA',
    INTEGRATION_DATA:'INTEGRATION_DATA',
    OPEN_DIALOG_UPGRADE_PLAN: 'OPEN_DIALOG_UPGRADE_PLAN',
    OPEN_DIALOG_SUCCESS_UPGRADE_PLAN: 'OPEN_DIALOG_SUCCESS_UPGRADE_PLAN',
    OPEN_DIALOG_DOWNLOAD_REPORT: 'OPEN_DIALOG_DOWNLOAD_REPORT',
    OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE: 'OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE',
    OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE: 'OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE',
    OPEN_DIALOG_DELETE_ACCOUNT: 'OPEN_DIALOG_DELETE_ACCOUNT',
    OPEN_DIALOG_DELETE_INTEGRATION: 'OPEN_DIALOG_DELETE_INTEGRATION',
    OPEN_DIALOG_REFRESH_SESSION: 'OPEN_DIALOG_REFRESH_SESSION',
    OPEN_DIALOG_COMPLETE_REGISTRATION: 'OPEN_DIALOG_COMPLETE_REGISTRATION',
    INTEGRATION_AUTH_CONFIG: "INTEGRATION_AUTH_CONFIG",
    METHOD: "METHOD",
    DOMAIN: "DOMAIN",
    INTEGRATION_NAME: "INTEGRATION_NAME",
    OPTIONAL_STEP_EXPANDED: "OPTIONAL_STEP_EXPANDED",
    SHOW_INTEGRATION_ADDED_SNACK: "SHOW_INTEGRATION_ADDED_SNACK",
    SHOW_INTEGRATION_DELETED_SNACK: "SHOW_INTEGRATION_DELETED_SNACK",
    SHOW_INTEGRATION_ADDED_OIDC_SNACK: "SHOW_INTEGRATION_OIDC_SNACK",
    SHOW_INTEGRATION_CHANGED_SNACK: "SHOW_INTEGRATION_CHANGED_SNACK",
    SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK: "SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK",
    OPEN_VIEW_DETAILS_INTEGRATION: "OPEN_VIEW_DETAILS_INTEGRATION",
    OPEN_EDIT_INTEGRATION: "OPEN_EDIT_INTEGRATION",
    SHOW_ACCESS_REVOKED_SNACK: "SHOW_ACCESS_REVOKED_SNACK",
    UPDATE_INTEGRATION_INFOS: "UPDATE_INTEGRATION_INFOS",
    SHOW_RESOLUTION_RECOMMENDATION_SNACK: "SHOW_RESOLUTION_RECOMMENDATION_SNACK",
    GRAPH_API_ACCESS_TOKEN: "GRAPH_API_ACCESS_TOKEN",
    SHOW_CREATE_AZURE_USER_SNACK : "SHOW_CREATE_AZURE_USER_SNACK",
    SHOW_USER_DETAILS : "SHOW_USER_DETAILS",
    OPEN_DIALOG_SEARCH_USER: "OPEN_DIALOG_SEARCH_USER",
    OPEN_DIALOG_DELETE_DEVICE: "OPEN_DIALOG_DELETE_DEVICE",
    OPEN_DIALOG_DELETE_USER: "OPEN_DIALOG_DELETE_USER",
    DEVICE_UUID: "DEVICE_UUID",
    DELETE_USER_INFO: "DELETE_USER_INFO",
    SHOW_DEVICE_DELETED_SNACK: "SHOW_DEVICE_DELETED_SNACK",
    SHOW_USER_DELETED_SNACK: "SHOW_USER_DELETED_SNACK",
    UPDATE_FEDERATION_INFOS: "UPDATE_FEDERATION_INFOS",
    REGISTRATION_FORM_FILLED: "REGISTRATION_FORM_FILLED"
};

const initialState = {
    isAuthUser : tryLocalStorageGetItem(ACTION_TYPE.AUTH_USER, 'false') === 'true',
    isSetupSuccess: false,
    isOpenedDialogInviteNewUser: false,
    isOpenedDialogAddAdmin: false,
    isOpenedDialogUpgradePlan: false,
    isOpenedDialogSuccessUpgradePlan: false,
    isOpenedDialogDownloadReport: false,
    isOpenedDialogAccountCheckFailure: false,
    isOpenedDialogAccountAccessRevoke: false,
    isOpenedDialogDeleteAccount: false,
    isOpenedDialogDeleteIntegration: false,
    isOpenedDialogRefreshSession: false,
    isOpenedDialogViewDetailsIntegration: false,
    isOpenedDialogEditIntegration: false,
    isOpenedDialogSearchUser: false,
    isOpenedDialogDeleteDevice: false,
    isOpenedDialogDeleteUser: false,
    isOpenedDialogCompleteRegistration: false,
    userData:{
        firstName:'',
        lastName:'',
        email:'',
        company:'',
        timeZone:'',
        lang:''
    },
    integrationData:{
        domain:'',
        integrationId:'',
        clientType: '',
        siteId: '',
        clientMetadataId: '',
        integrationName: '',
        loginMethod: '',
        fallbackLoginMethod: '',
        numberOfDevices: '',
        metadataUrl: '',
        clientMetadataId: 0,
        authenticationSettingsDto: null,
        file: null,
        isShared: false,
        numberOfSyncedUsers: 0,
        scimEndpointUrl: "",
        scimEnabled: false,
        scimSecret: "",
        licenseType: "",
        expirationDate: null,
        startDate: null,
        clientId: "",
        siemEnabled: false,
        workspaceId: "",
        serverHostname: "",
        serverPort: 0,
        siemType: "",
        serverName: null,
        domainVerified: false

    },
    integrationAuthConfig: { 
      WEBAUTHN_ENABLED: true,
      WEBAUTHN_MD_ENABLED:true,
      AUTHN_ENABLED:true,
      CMLA_ENABLED:false,
      WEBAUTHN_ACCESS_KEY_ENABLED:false,
    },
    method: "authn",
    fallbackMethod: undefined,
    domain: "",
    integration_name: "",
    optionalStepExpanded: false,
    showIntegrationAddedSnack: false,
    showIntegrationDeletedSnack: false,
    showIntegrationAddedOIDCSnack: false,
    showIntegrationChangedSnack: false,
    showReachedMaxIntegrationErrorSnack: false,
    showAccessRevokedSnack: false,
    showResolutionRecommendationSnack: false,
    showCreateAzureUserSnack: false,
    showDeviceDeletedSnack: false,
    showUserDeletedSnack: false,
    graphApiAccessToken: "",
    showUserDetails: false,
    createdAzureUserLink: "",
    deviceData: {},
    userDataEmail: "",
    isRegistrationFormFilled: false
};

export const context = createContext(initialState);

export const { Provider } = context;

export const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer((state, action) => {
        const currentState = {...state};
        switch (action.type) {
            case ACTION_TYPE.AUTH_USER:
                tryLocalStorageSetItem(ACTION_TYPE.AUTH_USER, JSON.stringify(action.payload));
                currentState.isAuthUser = action.payload;
                return currentState;
            case ACTION_TYPE.SETUP_SUCCESS:
                currentState.isSetupSuccess = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_INVITE_NEW_USER:
                currentState.isOpenedDialogInviteNewUser = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_ADD_ADMIN:
                currentState.isOpenedDialogAddAdmin = action.payload;
                return currentState;
            case ACTION_TYPE.USER_DATA:
                currentState.userData = action.payload;
                return currentState;
            case ACTION_TYPE.INTEGRATION_DATA:
                currentState.integrationData = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_UPGRADE_PLAN:
                currentState.isOpenedDialogUpgradePlan = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN:
                currentState.isOpenedDialogSuccessUpgradePlan = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_DOWNLOAD_REPORT:
                currentState.isOpenedDialogDownloadReport = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE:
                currentState.isOpenedDialogAccountCheckFailure = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE:
                currentState.isOpenedDialogAccountAccessRevoke = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT:
                currentState.isOpenedDialogDeleteAccount = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION:
                currentState.isOpenedDialogDeleteIntegration = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION:
                currentState.isOpenedDialogRefreshSession = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_COMPLETE_REGISTRATION:
                    currentState.isOpenedDialogCompleteRegistration = action.payload;
                    return currentState;
            case ACTION_TYPE.INTEGRATION_AUTH_CONFIG:
                currentState.integrationAuthConfig = action.payload;
                return currentState;
            case ACTION_TYPE.METHOD:
                currentState.fallbackMethod = action.payload.fallbackMethod;
                currentState.method = action.payload.method;
                return currentState;
            case ACTION_TYPE.OPTIONAL_STEP_EXPANDED:
                currentState.optionalStepExpanded = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_INTEGRATION_ADDED_SNACK:
                currentState.showIntegrationAddedSnack = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK:
                currentState.showIntegrationDeletedSnack = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_INTEGRATION_ADDED_OIDC_SNACK:
                currentState.showIntegrationAddedOIDCSnack = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK:
                currentState.showIntegrationChangedSnack = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_CREATE_AZURE_USER_SNACK:
                currentState.showCreateAzureUserSnack = action.payload.show;
                currentState.createdAzureUserLink = action.payload.link
                return currentState;
            case ACTION_TYPE.SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK:
                currentState.showReachedMaxIntegrationErrorSnack = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_USER_DETAILS:
                currentState.showUserDetails = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_VIEW_DETAILS_INTEGRATION:
                currentState.isOpenedDialogViewDetailsIntegration = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_EDIT_INTEGRATION:
                currentState.isOpenedDialogEditIntegration = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_ACCESS_REVOKED_SNACK:
                currentState.showAccessRevokedSnack = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_RESOLUTION_RECOMMENDATION_SNACK:
                currentState.showResolutionRecommendationSnack = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_SEARCH_USER:
                currentState.isOpenedDialogSearchUser = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_DELETE_DEVICE: 
                currentState.isOpenedDialogDeleteDevice = action.payload;
                return currentState;
            case ACTION_TYPE.OPEN_DIALOG_DELETE_USER:
                currentState.isOpenedDialogDeleteUser = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_DEVICE_DELETED_SNACK:
                currentState.showDeviceDeletedSnack = action.payload;
                return currentState;
            case ACTION_TYPE.SHOW_USER_DELETED_SNACK:
                currentState.showUserDeletedSnack = action.payload;
                return currentState;
            case ACTION_TYPE.UPDATE_INTEGRATION_INFOS:
                switch (action.payload.type) {
                    case "domain": 
                        currentState.integrationData.domain = action.payload.data;
                        return currentState;
                    case "file":
                        currentState.integrationData.file = action.payload.data;
                        return currentState;
                    case "url":
                        currentState.integrationData.metadataUrl = action.payload.data;
                        return currentState;
                    case "name":
                        currentState.integrationData.integrationName = action.payload.data;
                        return currentState;
                    default:
                        return currentState;
                }
            case ACTION_TYPE.GRAPH_API_ACCESS_TOKEN:
                currentState.graphApiAccessToken = action.payload;
                return currentState;
            case ACTION_TYPE.DEVICE_UUID: 
                 currentState.deviceData = action.payload;
                 return currentState;
            case ACTION_TYPE.DELETE_USER_INFO:
                currentState.userDataEmail = action.payload;
                return currentState;
            case ACTION_TYPE.REGISTRATION_FORM_FILLED:
                currentState.isRegistrationFormFilled = action.payload;
                return currentState;
            default:
                throw new Error();

        }}, initialState);
    return <Provider value={{state, dispatch}}>{children}</Provider>
};
