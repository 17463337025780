import React from 'react';
import Typography from "@mui/material/Typography/Typography";
import MuiDivider from '@mui/material/Divider';
import {styled} from "@mui/material";
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomSwitch } from './CustomUI';

export default function IntegrationDetailsSection(props) {

    const {heading, content, isEditModeOn, divide, checked, subheadingNeeded, handleChangeSwitch, handleChangeState } = props;
    const { t } = useTranslation();
    
    let isScimChecked = (heading === t('integration_view.scim_provisioning') && checked) || (heading !== t('integration_view.scim_provisioning'));
    let isScimOrSiemChecked = ((heading === t('integration_view.scim_provisioning')) || (heading === t('add_integration.siem_heading'))) && checked;
    let isNotScimOrSiem = (heading !== t('integration_view.scim_provisioning')) && (heading !== t('add_integration.siem_heading'))
    let changeOfHeight = isScimOrSiemChecked || isNotScimOrSiem;
    let showSwitch = (heading === t('integration_view.scim_provisioning')) || (heading === t('add_integration.siem_heading')) && isEditModeOn;
    let showHeading = (heading !== t('integration_view.scim_provisioning')) && !(heading === t('add_integration.siem_heading') && isEditModeOn);

    const Divider = styled(MuiDivider) (({theme}) => ({
        marginBottom: 22,
        width: '80%',
        marginTop: 20
    }));


    return (
        <div style={{marginTop: 45, position: 'relative'}}>
            <div style={{whiteSpace:'pre-wrap', marginBottom: 30}}>
                <Typography variant="headingTextViewSection" component="div" gutterBottom style={{ display: 'inline-block' }} >
                {showSwitch && <FormControlLabel
                                value={heading}
                                control={<CustomSwitch color="primary" onChange={handleChangeSwitch} checked={checked} sx={{marginLeft: '80px', marginTop: heading === t('integration_view.scim_provisioning') ? '-30px' : '0px'}} id="enableScimSwitchEdit" /> }
                                label={heading}
                                labelPlacement="start"
                                componentsProps={{typography : {variant: 'headingTextViewSection'}}}
                                 />
                                }
                {showHeading && heading}
                </Typography>
            </div>
            {
                subheadingNeeded === true && <div style = {{marginBottom: '4vh', whiteSpace:'pre-wrap', textAlign:'left', visibility: isScimChecked ? "visible" : "hidden", height: isScimChecked ? "100%" : "0px"}}> {(t('integration_view.user_provisioning'))} </div> 
            }
            <div style={{height: changeOfHeight  ? "100%" : "0px" }} >
                {content}
            </div>
            {divide && <Divider/>}
        </div>
    );
}