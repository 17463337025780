import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import MuiDivider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { context, ACTION_TYPE } from "../State";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from "react-i18next";
import { minHeight, typography } from '@mui/system';
import { CustomSwitch } from './CustomUI';
export default function Section(props) {

    const {heading, content, width, isErrorOccurred, handleChange, checked} = props;
    const { t } = useTranslation();

    const state = React.useContext(context);
    
    const Divider = styled(MuiDivider)({
        borderLeft:'5px solid', 
        borderColor: isErrorOccurred === true ? '#C72941' : '#293DC7', 
        borderRight: 'none', 
        borderTop: 'none', 
        borderBottom: 'none', 
        textAlign: 'left',
        width: width
    });
    

    return (
        <Grid textAlign={'left'}>
            <Grid textAlign={'left'}>
                <Divider
                    orientation={'vertical'} 
                >
                    <div style={{ marginLeft: '32px', whiteSpace:'pre-wrap', marginTop: '-30px', height: (((heading === t('add_integration.scim_provisioning') || heading === t('add_integration.siem_heading') ) && checked) || (heading !== t('add_integration.scim_provisioning') && heading !== t('add_integration.siem_heading') )) ? "100%" : "0px" }}>
                        <Typography variant="sectionHeading" component="div" gutterBottom style={{ marginBottom: '35px' }}>
                            {((heading === t('add_integration.scim_provisioning') || heading === t('add_integration.siem_heading')) && <FormControlLabel
                                value={heading}
                                control={<CustomSwitch color="primary" onChange={handleChange} checked={checked} sx={{marginLeft: '110px'}} id="enableScimSwitchAdd" />}
                                label={heading}
                                labelPlacement="start"
                                componentsProps={{typography : {variant: 'sectionHeading'}}} />
                                )}
                            {(heading !== t('add_integration.scim_provisioning') && heading !== t('add_integration.siem_heading') ) && heading}
                        </Typography>
                        <div style={{ marginBottom: '-20px', visibility: (((heading === t('add_integration.scim_provisioning') || heading === t('add_integration.siem_heading') ) && checked) || (heading !== t('add_integration.scim_provisioning') && heading !== t('add_integration.siem_heading') )) ? "visible" : "hidden" }}>
                            {content}
                        </div>
                    </div>
                </Divider>
            </Grid>
        </Grid>
    );
}
