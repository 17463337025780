export const msalConfig = {
    auth: {
        // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
        clientId: "",
        authority: "https://login.microsoftonline.com/common/"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const createUserTokenRequest = {
    scopes: ["User.ReadWrite.All", "Directory.ReadWrite.All"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const federateDomainTokenRequest = {
    scopes: ["Domain.ReadWrite.All", "Directory.Read.All", "Directory.AccessAsUser.All", "User.ReadWrite.All"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}
