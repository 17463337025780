import React, { useEffect, useState, useRef } from "react";
import { context, ACTION_TYPE } from "../State";
import Dialog from '@mui/material/Dialog';
import { DialogContent, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { TextFieldSerach } from "./CustomUI";
import IconButton from "@mui/material/IconButton"
import { ExitButton } from "./CustomUI";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Api from "../helpers/service";
import { log, tryLocalStorageGetItem, validateEmail } from "../helpers/utils";
import { formatDate } from "../helpers/utils";
import { styled } from "@mui/material";
import MuiTableCell from "@mui/material/TableCell"
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search"
import InputAdornment from "@mui/material/InputAdornment";
import MuiClearIcon from "@mui/icons-material/Clear";
import deleteIcon from "../img/icon-delete.svg";
import SelectItemList from "./SelectListMenu";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from "./CustomUI";
import CheckIcon from '@mui/icons-material/Check';
import Countdown from 'react-countdown';


export default function DialogTableSearchUsers(props) {
    const { open, onCancel, title, id } = props;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [isShown, setIsShown] = React.useState(null);
    const [integrations, setIntegrations] = React.useState(null);
    const [devices, setDevices] = React.useState(null);
    const [accessKey, setAccessKey] = React.useState(null);
    const [inviteLink, setInviteLink] = React.useState(null);
    const [userFound, setUserFound] = React.useState(true);
    const [savedUserEmail, setSavedUserEmail] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [uuid, setUuid] = React.useState(null);

    const tableRowRef = useRef([]);


    const { state, dispatch } = React.useContext(context);
    const { t } = useTranslation();
    const api = new Api();

    let userEmail = "";

    let rowActionMenuItems = [
        { text: t('select_menu.delete_device'), imageUrl: deleteIcon, isDisabled: "false", type: "search", func: handleOpenDialogDeleteDevice }
    ];


    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    const handleClick = (index, event, rowUuid) => {
        setAnchorEl(tableRowRef.current[index]);
        setIsShown(true);
        setUuid(rowUuid);
    };

    function handleDialogClose(event) {
        setOpenDialog(false);
        setIsShown(null);
        onCancel(false);
        setSavedUserEmail("");
        setUserFound(true);
    };

    function handleRevokeInviteLink(event) {
        api.revokeInviteLink(savedUserEmail).then(function (res) {
            log(res);
            setInviteLink(null);
        }).catch(function (error) {
            log(error);
        })
    }
    function handleRevokeCode(event) {
        api.revokeCode(savedUserEmail).then(function (res) {
            log(res);
            setAccessKey(null);
        }).catch(function (error) {
            log(error);
        })
    }

    const searchUser = () => {
        api.searchUsers(savedUserEmail).then(function (res) {
            setIntegrations(res.data.integrations);
            setDevices(res.data.devices);
            setAccessKey(res.data.managedCode);
            setInviteLink(res.data.inviteLink);
            setUserFound(true);
        }).catch(function (error) {
            log(error)
            setUserFound(false);
            setIntegrations(null);
            setDevices(null);
            setInviteLink(null);
            setAccessKey(null);
        })
    };

    const ClearIcon = styled(MuiClearIcon)({
        transform: 'translateY(25%)',
        color: 'red',
        height: 15,
        minWidth: 24
    })


    const TableCell = styled(MuiTableCell)({
        textAlign: 'left',
        font: 'normal normal normal 14px/18px Source Sans Pro',
        letterSpacing: '0px',
        color: '#152035',
        opacity: 1
    });


    const handleTextFiledInput = (event) => {
        userEmail = event.target.value;
        setSavedUserEmail(userEmail);
    };

    const handleClose = (index, event) => {
        setIsShown(false);
    };

    function handleOpenDialogDeleteDevice(index, event) {
        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_DEVICE, payload: true });
        dispatch({ type: ACTION_TYPE.DEVICE_UUID, payload: { deviceUuid: uuid, ownerEmail: savedUserEmail } });
    }

    function handleOpenDialogDeleteUser(event) {
        if (validateEmail(savedUserEmail) && userFound) {
            dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_USER, payload: true });
            dispatch({ type: ACTION_TYPE.DELETE_USER_INFO, payload:  savedUserEmail  });
        }
    }

    function calculateTimeLeft(time) {
        return time + 12*60*60*1000;
    }

    const renderer = ({hours, minutes}) => {
        return <span>{hours}h {minutes} min</span>
    };

    return (
        <Dialog
            id={id}
            open={openDialog}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: '8px',
                    opacity: 1,
                    maxWidth: '800px',
                    width: '800px'
                },
            }}
        >
            <DialogContent style={{ textAlign: "center", width: "fit-content", marginLeft: '50px', marginRight: '0px', paddingRight: '0px' }}>
                <IconButton onClick={(event) => { handleDialogClose(event); setIntegrations(null); setDevices(null); setInviteLink(null); setAccessKey(null); }} style={{ float: 'right', transform: 'translateY(-50%)' }}>
                    <ExitButton />
                </IconButton>

                <TextFieldSerach className="inputRounded" id="outlinedBasic" style={{transform: 'translateX(-50%)', marginBottom: '50px'}}
                    placeholder={t('dialog.search_user_button')}
                    onChange={(event) => handleTextFiledInput(event)} onKeyPress={(event) => { if (event.key === 'Enter') searchUser() }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='end'>
                                <SearchIcon style={{ transform: 'translateY(-15px)', marginLeft: 0 }} />
                            </InputAdornment>
                        )
                    }}
                >
                </TextFieldSerach>
                {!userFound &&
                    <div style={{ fontSize: '10px', marginTop: '-40px', transform: 'translateX(-45%)', marginBottom: '50px' }}>
                        <ClearIcon />
                        <span style={{ color: 'red' }}>{t('dialog.search_user_not_found')}</span>
                    </div>
                }
                <Typography style={{ paddingBottom: '0vh', marginBottom: '-10px' }} variant="headingTextViewSection" component="div">
                    {t('dialog.search_user_devices')}
                </Typography>

                <div style={{ display: 'inline-block', transform: 'translate(150px, -25%)', cursor: 'pointer' }} onClick={(e) => handleOpenDialogDeleteUser(e)}>
                    <Typography type="body2" style={{ textAlign: 'left', font: 'normal normal normal 14px/20px Source Sans Pro', letterSpacing: '0px', color: '#152035', opacity: 1 }}> {t('select_menu.delete_user')}</Typography>
                    <img src={deleteIcon} style={{ transform: 'translateY(-110%)', marginLeft: '120%' }} />
                </div>
                <TableContainer style={{ width: "600px", height: '200px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {t('dialog.search_user_name')}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {t('dialog.search_user_registered')}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>

                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {devices !== null && devices.map((row, index) => (
                                <TableRow
                                    key={index}
                                    ref={(el) => { (tableRowRef.current[index]) = el }}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    id={row.deviceUuid}
                                >
                                    <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>{row.name}</TableCell>
                                    <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>{formatDate(row.created)}</TableCell>
                                    <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                        <span >
                                            <MoreHorizIcon sx={{ width: '3.5vw', height: '3.5vh' }} onClick={(e) => { handleClick(index, e, row.deviceUuid) }} />
                                        </span>
                                    </TableCell>
                                    <SelectItemList open={Boolean(isShown)} onClose={handleClose} items={rowActionMenuItems} anchorEl={anchorEl} isFromSearchDialog={true} />
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>

                </TableContainer>




                <Typography style={{ paddingBottom: '2vh', marginTop: '37px' }} variant="headingTextViewSection" component="div">
                    {t('dialog.search_user_integrations')}
                </Typography>
                <TableContainer style={{ width: "600px", height: '200px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {t('dialog.search_user_name')}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {t('dialog.search_user_type')}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {t('dialog.search_user_domain')}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {t('dialog.search_user_last_login')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {integrations !== null && integrations.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>{row.name}</TableCell>
                                    <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>{row.type}</TableCell>
                                    <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>{row.domain}</TableCell>
                                    <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>{formatDate(row.last_login)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography style={{ paddingBottom: '3vh'}} variant="headingTextViewSection" component="div">
                    {t('dialog.access_key_or_link_title')}
                </Typography>
                <TableContainer style={{ width: "600px", height: '200px' }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{opacity:accessKey !== null ? 1 : 0.3}}>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    <span>
                                        {accessKey !== null && <CheckIcon fontSize="small" sx ={{color:"#4557CC", marginBottom: '-5px'}}/>}
                                        {t('dialog.search_user_access_key')}
                                    </span>
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {accessKey !== null && t('dialog.search_user_used_times') + (accessKey.allowedTimes - accessKey.numberOfUsages) + '/ '+ accessKey.allowedTimes}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {accessKey !== null && accessKey.validFromTime !== null
                                                        && accessKey.validFromTime <= Date.now() && <span> {t('dialog.search_user_valid_time')} <Countdown date = {calculateTimeLeft(accessKey.validFromTime)} daysInHours = {true} renderer = {renderer}/></span>
                                    }
                                    {accessKey !== null && accessKey.validFromTime !== null
                                                        && accessKey.validFromTime > Date.now() && <span> {t('dialog.search_user_valid_in_time')} <Countdown date = {accessKey.validFromTime} daysInHours = {true} renderer = {renderer}/>   </span>
                                    }
                                    {accessKey !== null && accessKey.validFromTime === null
                                                        && <span> {t('dialog.search_user_valid_time')} <Countdown date = {calculateTimeLeft(accessKey.created)} daysInHours = {true} renderer = {renderer}/></span>
                                    }
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {accessKey !== null && 
                                        <Link onClick={event => handleRevokeCode(event)}>
                                            {t('dialog.search_user_revoke_access')}
                                        </Link> 
                                    }
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow style={{opacity:inviteLink !== null ? 1 : 0.3}}>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    <span>
                                        {inviteLink !== null && <CheckIcon fontSize="small" sx ={{color:"#4557CC", marginBottom: '-5px'}}/>}
                                        {t('dialog.search_user_invite_link')}
                                    </span>
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    { inviteLink !== null && t('dialog.search_user_used_times') + '0 / 1'}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    { inviteLink !== null && <span> {t('dialog.search_user_valid_time')} <Countdown date ={calculateTimeLeft(inviteLink.created)} daysInHours = {true} renderer = {renderer}/></span> }
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0, fontSize: '14px' }}>
                                    {inviteLink !== null &&
                                        <Link onClick={event => handleRevokeInviteLink(event)}>
                                            {t('dialog.search_user_revoke_access')}
                                        </Link>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>

            </DialogContent>
        </Dialog>
    )

}