import React from "react";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Typography } from "@mui/material";
import { OutlinedInput } from "@mui/material";
import { ErrorSpan } from "./CustomUI";
import errorIcon from "../img/error-icon.svg";
import { isValidBase64, isValidDomain } from "../helpers/utils";
import { isValidUUID } from "../helpers/utils";
import { context } from "../State";

export default function SIEMSection(props) {

    const { t, inSidebar, checked, selectedSiem, setSelectedSiem, values, errors} = props;

    const { state } = React.useContext(context)
    const [workspaceIdSection, setWorkspaceIdSection] = React.useState(inSidebar ? state.integrationData.workspaceId : '');
    const [primaryKeySection, setPrimaryKeySection] = React.useState('');
    const [serverHostnameSection, setServerHostnameSection] = React.useState(inSidebar ? state.integrationData.serverHostname : '');
    const [serverPortSection, setServerPortSection] = React.useState(inSidebar ? state.integrationData.serverPort : '');
    const [errorPrimaryKey, setErrorPrimaryKey] = React.useState(false);
    const [errorMessagePrimaryKey, setErrorMessagePrimaryKey] = React.useState('');
    const [errorWorkspaceId, setErrorWorkspaceId] = React.useState(false);
    const [errorMessageWorkspaceId, setErrorMessageWorkspaceId] = React.useState('');
    const [errorServerHostname, setErrorServerHostname] = React.useState(false);
    const [errorMessageServerHostname, setErrorMessageServerHostname] = React.useState('');
    const [errorServerPort, setErrorServerPort] = React.useState(false);
    const [errorMessageServerPort, setErrorMessageServerPort] = React.useState('');
    const [timer, setTimer] = React.useState(null);
    
    const handleWorkspaceIdChange = event => {
        setWorkspaceIdSection(event.target.value);
        values["workspaceId"] = event.target.value;
        clearTimeout(timer)

        //timer is added to delay validation untill user finishes typing 
        const newTimer = setTimeout(() => {
            if (!isValidUUID(values["workspaceId"]) || values["workspaceId"] === "" && selectedSiem === "SENTINEL") {
                setErrorMessageWorkspaceId(t('add_integration.errors.invalid_workspace_id'));
                setErrorWorkspaceId(true);
                errors["workspaceId"] = true;
            } else {
                setErrorMessageWorkspaceId("");
                setErrorWorkspaceId(false);
                errors["workspaceId"] = false;
            }
        }, 500)

        setTimer(newTimer); 
    }

    const handlePrimaryKeyChange = event => {
        setPrimaryKeySection(event.target.value);
        values["primaryKey"] = event.target.value;

        clearTimeout(timer)
        //timer is added to delay validation untill user finishes typing 
        const newTimer = setTimeout(() => {
            if (!isValidBase64(values["primaryKey"]) || values["primaryKey"] === "" && selectedSiem === "SENTINEL") {
                setErrorMessagePrimaryKey(t('add_integration.errors.invalid_primary_key'));
                setErrorPrimaryKey(true);
                errors["primaryKey"] = true;
            } else {
                setErrorMessagePrimaryKey("");
                setErrorPrimaryKey(false);
                errors["primaryKey"] = false;
            }
        }, 500)

        setTimer(newTimer);
    }

    const handleServerHostnameChange = event => {
        setServerHostnameSection(event.target.value);
        values["serverHostname"] = event.target.value;

        clearTimeout(timer)
        //timer is added to delay validation untill user finishes typing 
        const newTimer = setTimeout(() => {
            if (!isValidDomain(values["serverHostname"]) && selectedSiem === "SYSLOG_QRADAR") {
                setErrorMessageServerHostname(t('add_integration.errors.invalid_server_hostname'));
                setErrorServerHostname(true);
                errors["serverHostname"] = true;
            } else {
                setErrorMessageServerHostname("");
                setErrorServerHostname(false);
                errors["serverHostname"] = true;
            }
        }, 500)

        setTimer(newTimer);
    }

    const handleServerPortChange = event => {
        setServerPortSection(event.target.value);
        values["serverPort"] = event.target.value;

        clearTimeout(timer)
        //timer is added to delay validation untill user finishes typing 
        const newTimer = setTimeout(() => {
            if (isNaN(values["serverPort"]) || Number(values["serverPort"]) < 1 || Number(values["serverPort"]) > 65535 && selectedSiem === "SYSLOG_QRADAR") {
                setErrorMessageServerPort(t('add_integration.errors.invalid_server_port'));
                setErrorServerPort(true);
                errors["serverPort"] = true;
            } else {
                setErrorMessageServerPort("");
                setErrorServerPort(false);
                errors["serverPort"] = true;
            }
        }, 500)

        setTimer(newTimer);

    }

    const handleChangeSelection = (event) => {
        console.log("SIEM change: " + event.target.value);
        setSelectedSiem(event.target.value);
    };


    return (
        
        <div style={{ whiteSpace: 'pre-wrap', visibility: checked ? 'visible' : 'hidden' }}>

                <div style={ inSidebar ? { marginRight: '130px' } : {} }>
                    <FormControl variant="standard" sx={{ m: 1, width: "100%"}}>
                        <InputLabel id="siem-select-standard-label">Siem type</InputLabel>
                        <Select
                            labelId="siem-select-standard-label"
                            id="siem-select-standard"
                            value={selectedSiem}
                            onChange={handleChangeSelection}
                            label="Type"
                        >
                            <MenuItem value={"SENTINEL"}>Microsoft Sentinel</MenuItem>
                            <MenuItem value={"SYSLOG_QRADAR"}>Q-Radar Syslog</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {selectedSiem === "SENTINEL" &&
                <>
                    {t('')}
                    <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', marginTop: '80px'  }}>
                        {t('add_integration.siem_workspace_id')}
                    </Typography>
                    <div>
                        <OutlinedInput value={workspaceIdSection} style={{ background: "#F2F2F2 0% 0% no-repeat padding-box", width: "80%"}} onChange={(event) => handleWorkspaceIdChange(event)} />
                    </div>
                    { errorWorkspaceId &&
                        <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                            <img src={errorIcon} alt={"err"} />
                            <ErrorSpan>{ errorMessageWorkspaceId }</ErrorSpan>
                        </div>
                    }
                    <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', marginTop: '30px'  }}>
                        {t('add_integration.siem_primary_key')}
                    </Typography>
                    <div>
                        <OutlinedInput value={primaryKeySection} style={{ background: "#F2F2F2 0% 0% no-repeat padding-box", width: "80%"}} onChange={(event) => handlePrimaryKeyChange(event)} />
                    </div>
                    { errorPrimaryKey &&
                        <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                            <img src={errorIcon} alt={"err"} />
                            <ErrorSpan>{ errorMessagePrimaryKey }</ErrorSpan>
                        </div>
                    }
                </>}
                {selectedSiem === "SYSLOG_QRADAR" &&
                <>
                    <div style={{ marginTop: '80px' }}>
                        {t('add_integration.siem_qradar_note')}
                    </div>
                    <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', marginTop: '30px'  }}>
                        {t('add_integration.siem_server_hostname')}
                    </Typography>
                    <div>
                        <OutlinedInput value={serverHostnameSection} style={{ background: "#F2F2F2 0% 0% no-repeat padding-box", width: "80%"}} onChange={(event) => handleServerHostnameChange(event)} />
                    </div>
                    { errorServerHostname &&
                        <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                            <img src={errorIcon} alt={"err"} />
                            <ErrorSpan>{errorMessageServerHostname}</ErrorSpan>
                        </div>
                    }
                    <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', marginTop: '30px'  }}>
                        {t('add_integration.siem_server_port_number')}
                    </Typography>
                    <div>
                        <OutlinedInput value={serverPortSection} style={{ background: "#F2F2F2 0% 0% no-repeat padding-box", width: "80%"}} onChange={(event) => handleServerPortChange(event)} />
                    </div>
                    { errorServerPort &&
                        <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                            <img src={errorIcon} alt={"err"} />
                            <ErrorSpan>{errorMessageServerPort}</ErrorSpan>
                        </div>
                    }
                </>}

        </div> )
    

}