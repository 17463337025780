import React from "react";
import CircleIcon from '@mui/icons-material/Circle';
//icons
import oktaIcon from "../img/okta.svg";
import googleIcon from "../img/google.svg";
import salesforceIcon from "../img/salesforce.svg";
import zendeskIcon from "../img/zendesk.svg";
import officeIcon from "../img/office.svg";
import awsIcon from '../img/aws.svg';
import oidcIcon from "../img/openId.png";
import teamviewerIcon from "../img/teamviewer-icon.svg"
import keeperIcon from "../img/keeper.png"
import AddIcon from '@mui/icons-material/Add';
import ToolTipComponent from "../components/TooltipComponent";
import TimeAgo from 'react-timeago'

let MICROSOFT = "Microsoft";
let OKTA = "Okta";
let SALESFORCE = "Salesforce";
let AWS = "AWS Single Sign On";
let GSUITE = "Google";
let ZENDESK = "Zendesk";
let CUSTOM = "SAML application";
let OIDC = "OIDC application";
let TEAMVIEWER = "TeamViewer";
let KEEPER = "Keeper";
let millsecInDay = 86400000;

export default class Integration {
    constructor(id,
                imagePath,
                name,
                domain,
                licenseType ,
                licenseLimit,
                licenseTotal,
                enrolledUsers,
                scim,
                scimStatus,
                branding,
                expirationDate,
                numberOfUsers) {
        this._id = id;
        this._imagePath = imagePath;
        this._name = name;
        this._domain = domain;
        this._licenseType = licenseType;
        this._licenseLimit = licenseLimit;
        this._licenseTotal = licenseTotal;
        this._enrolledUsers = enrolledUsers;
        this._scim = scim;
        this._scimStatus = scimStatus;
        this._branding = branding;
        this._expirationDate = expirationDate;
        this._numberOfUsers = numberOfUsers;
    }

    get id() {
        return this._id;
    }

    get imagePath() {
        return this._imagePath;
    }

    get name() {
        return this._name;
    }

    get domain() {
        return this._domain;
    }

    get licenseType() {
        return this._licenseType;
    }

    get lUsers() {
        return this._lUsers;
    }

    get enrolledUsers() {
        return this._enrolledUsers;
    }

    get scim() {
        return this._scim;
    }

    get scimStatus() {
        return this._scimStatus;
    }

    get branding() {
        return this._branding;
    }

    get expirationDate() {
        return this._expirationDate;
    }

    get licenseLimit() {
        return this._licenseLimit;
    }

    get licenseTotal() {
        return this._licenseTotal;
    }

    get numberOfUsers() {
        return this._numberOfUsers;
    }

    static tableHeaders() {
        return [
            '',
            'Name',
            'Domain',
            /*'Branding'*/
            'License-Type',
            'Licensed Users',
            'Enrolled Users',
            'SCIM on/off',
            'SCIM status',
            '',
            ''
        ];
    }
    
    

    /**
     * Converts Integration obj gotten from the server to the one used for displaying in the view
     * @param integration
     * @returns {Integration}
     */
    static toIntegration(integration) {
        let type = integration.clientType !== null ? integration.clientType : integration.integrationType;
        var integrationIcon = null;
        var title = null;
        switch(type) {
            case "OKTA":
                integrationIcon = oktaIcon;
                title = OKTA;
                break;
            case "MICROSOFT":
                integrationIcon = officeIcon;
                title = MICROSOFT;
                break;
            case "GSUITE":
                integrationIcon = googleIcon;
                title = GSUITE;
                break;
            case "SALESFORCE":
                integrationIcon = salesforceIcon;
                title = SALESFORCE;
                break;
            case "AWS":
                integrationIcon = awsIcon;
                title = AWS;
                break;
            case "ZENDESK":
                integrationIcon = zendeskIcon;
                title = ZENDESK;
                break;
            case "TEAMVIEWER":
                integrationIcon = teamviewerIcon;
                title = TEAMVIEWER;
                break;
            case "OIDC":
                integrationIcon = oidcIcon;
                title = OIDC;
                break;
            case "KEEPER": 
                integrationIcon = keeperIcon;
                title = KEEPER;
                break;
        }
        let today = new Date().getTime();
        let expirationDayinMilliseconds = integration.expirationDate;
        let milliseconds = expirationDayinMilliseconds - today;
        let trialText =  milliseconds <= 0 ? " expired " : Math.round(milliseconds/millsecInDay)+ " days left";
        let licenseTypeText = integration.licenseType !== undefined ? (integration.licenseType === "Licensed" ? "Licensed" :  "Trial: " + trialText) : "-"; 
        let licenseLimit = (integration.licenseLimit !== undefined && integration.licenseLimit !== null) ? (integration.licenseLimit < 1000000 ? integration.licenseLimit : '∞') : '';

        return new Integration(integration.id,
            integration.clientType !== "CUSTOM" ? <img src={integrationIcon} style={{height: '5vh', width: '5vw'}} title={title}/>: <AddIcon  titleAccess={CUSTOM} style={{
                font: 'normal normal bold 40px/21px Source Sans Pro',
                color: 'black',
                margin: 'auto',
                width: '5vw',
                height: '5vh'
            }} />,
            integration.integrationName,
            integration.domain ?? "-",
            /*"-",
            "-",
            <CircleIcon style={{ color: "#293dc7" }}/>)*/
            <div style={{display: 'flex'}}> <span style ={{opacity: milliseconds > 0 ? 1 : 0.3}}>{licenseTypeText}</span> { <ToolTipComponent milliseconds={milliseconds} isIntegrationActive={milliseconds>0} licenseType={integration.licenseType}/> }</div>,
            (integration.licenseLimit === null || integration.licenseTotal === null || integration.licenseLimit === undefined || integration.licenseTotal === undefined) ? "-" : integration.licenseTotal + " / " + licenseLimit,
            //if Microsoft show licenseTotal/enrolledUsers else show licenseTotal in enorlled users column
            ((integration.scimEnabled && integration.numberOfUsers != null) ? integration.numberOfUsers : (integration.licenseTotal !== null ? integration.licenseTotal : '-')) + ( " / " + (integration.enrolledUsers !== null ? integration.enrolledUsers : "-")),
            integration.scimEnabled ? "on" : "off",
            integration.scimEnabled ? <TimeAgo date = {integration.lastSync}/> : "-",
            "",
            "",
            integration.expirationDate ?? "-")
    }

}