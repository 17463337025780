import React, { useEffect, useState, useRef } from "react";
import {styled, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {log} from '../helpers/utils'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {context, ACTION_TYPE} from "../State";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RadioInput = ({label, value, checked, setter, style}) => {
	return (
    <div>
	  <label style={{background: 'transparent'}}>
	    <input type="radio" checked={checked === value} onChange={(event) => setter(value, event)}/>
	    <span style={{marginLeft: '10px'}}>
        {label}
      </span>
	  </label>
    </div>
	);
};

const TableCell = styled(MuiTableCell)({
    textAlign: 'left',
    font: 'normal normal normal 14px/18px Source Sans Pro',
    letterSpacing: '0px',
    color: '#152035',
    opacity: 1,
    width: 100,
    maxWidth: 100,
    whiteSpace: "break-spaces",
    verticalAlign: "text-top",
    borderBottomWidth: '2px'

  });

  const TableCellHeading = styled(TableCell)({
    font: 'normal normal 600 16px/22px Source Sans Pro',
    textAlign: 'left'
  });


export default function MethodChooseOidc (props) {
  const {t} = useTranslation();
  const {oidcConfiguration, setOidcConfiguration} = props;
  const {dispatch, state} = React.useContext(context);

  //algorithms and methods 
  let signingAlgorithms = ["HS256", "HS384", "HS512", "RS256", "RS384", "RS512", "ES256", "ES384", "ES512" ]; // default RS256
  let encryptionAlgorithms = [ "none", "RSA-OAEP-256", "RSA-OAEP", "RSA1_5", "A128KW", "A192KW", "A256KW", "dir", "ECDH-ES", "ESDH-ES+A128KW", "ESDH-ES+A192KW", "ESDH-ES+A256KW" ];  //default none
  let encryptionAlgorithms_enc = ["none", "A128CBC-HS256", "A192CBC-HS384", "A256CBC-HS512","A128GCM", "A192GCM", "A256GCM", "A128CBC+HS256", "A256CBC+HS512" ]; //default none
  let  authenticationMethods = ["client_secret_post", "client_secret_basic", "client_secret_jwt", "private_jwt"]; //default client_secret_post

  function updateSigningAlg(alg, event) {
    let newConfiguration = {
      signingAlg: alg,
      encryptionAlg: oidcConfiguration.encryptionAlg,
      encryptionMeth: oidcConfiguration.encryptionMeth,
      authenticationMeth: oidcConfiguration.authenticationMeth
    }
    setOidcConfiguration(newConfiguration);
  }

  function updateEncryptionAlg(alg, event) {
    let newConfiguration = {
      signingAlg: oidcConfiguration.signingAlg,
      encryptionAlg: alg,
      encryptionMeth: oidcConfiguration.encryptionMeth,
      authenticationMeth: oidcConfiguration.authenticationMeth
    }

    setOidcConfiguration(newConfiguration);
  }

  function updateEncryptionMeth(meth, event) {
    let newConfiguration = {
      signingAlg: oidcConfiguration.signingAlg,
      encryptionAlg: oidcConfiguration.encryptionAlg,
      encryptionMeth: meth,
      authenticationMeth: oidcConfiguration.authenticationMeth
    }
    setOidcConfiguration(newConfiguration);
  }

  function updateAuthenticationMeth(meth, event) {
    let newConfiguration = {
      signingAlg: oidcConfiguration.signingAlg,
      encryptionAlg: oidcConfiguration.encryptionAlg,
      encryptionMeth: oidcConfiguration.encryptionMeth,
      authenticationMeth: meth
    }
    setOidcConfiguration(newConfiguration);
  }

  const toggleAccordion = () => {
    console.log("Toggle accordion");
    dispatch({type: ACTION_TYPE.OPTIONAL_STEP_EXPANDED, payload: !state.optionalStepExpanded});
  }

  return (
    <div style={{width: '120%'}}>
      <Accordion expanded={state.optionalStepExpanded} defaultExpanded={state.optionalStepExpanded} style={{boxShadow: 'none'}}>
        <AccordionSummary
         expandIcon={<ExpandMoreIcon onClick={toggleAccordion}/>}
         aria-controls="panel1a-content"
        >
          <Typography style={{fontWeight: 600}}>{t('add_integration.oidc_config')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 600}}>{t('add_integration.signing_alg')}</TableCell>
                <TableCell style={{fontWeight: 600}}>{t('add_integration.encryption_alg')}</TableCell>
                <TableCell style={{fontWeight: 600}}>{t('add_integration.encryption_meth')}</TableCell>
                <TableCell style={{fontWeight: 600}}>{t('add_integration.authentication_meth')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell>
                  {
                    signingAlgorithms.map((alg, index) => (
                    <div style={{marginTop:10}}>
                      <RadioInput 
                        element="div" 
                        label={alg} 
                        value={alg} 
                        checked={oidcConfiguration.signingAlg} 
                        setter={(alg, event) => updateSigningAlg(alg, event)}/>
                    </div>
                  ))}
                </TableCell>

                <TableCell>
                  {
                    encryptionAlgorithms.map((alg, index) => (
                      <div style={{marginTop:10}}>
                        <RadioInput 
                          sx={{
                            color: "#293DC7",
                            '&.Mui-checked': {
                              color: "#293DC7",
                            },
                          }}
                          element="div" 
                          label={alg} 
                          value={alg} 
                          checked={oidcConfiguration.encryptionAlg} 
                          setter={(alg, event) => updateEncryptionAlg(alg, event)}/>
                      </div>
                    ))
                  }
                </TableCell>

                <TableCell>
                  {
                    encryptionAlgorithms_enc.map((meth, index) => (
                      <div style={{marginTop:10}}>
                        <RadioInput 
                          element="div" 
                          label={meth} 
                          value={meth} 
                          checked={oidcConfiguration.encryptionMeth} 
                          setter={(meth, event) => updateEncryptionMeth(meth, event)}/>
                      </div>
                    ))
                  }
                </TableCell>

                <TableCell>
                  {
                    authenticationMethods.map((meth, index) => (
                      <div style={{marginTop:10}}>
                      <RadioInput 
                        element="div" 
                        label={meth} 
                        value={meth} 
                        checked={oidcConfiguration.authenticationMeth} 
                        setter={(meth, event) => updateAuthenticationMeth(meth, event)}/>
                      </div>
                    ))
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
  </div>
        
  );
}