import React from 'react';
import App from './App';
import { HashRouter } from "react-router-dom";
import {StateProvider} from "./State";
import { createRoot } from 'react-dom/client';
import './i18n/config';


function render() {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
            <HashRouter>
                    <StateProvider>
                        <App />
                    </StateProvider>
            </HashRouter>
    );
}

// Create a mock server if we're using development environment
if (process.env.NODE_ENV === 'development') {
    import('./MockServer').then((mockServer) => {
        mockServer.createMockServer({ environment: 'development' });
        render();
    });
} else {
    render();
}
