import React, { useEffect } from "react";
import { styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { context, ACTION_TYPE } from "../State";

const RadioInput = ({ label, value, checked, setter, style }) => {
  return (
    <div>
      <label style={{ background: 'transparent' }}>
        <input type="radio" checked={checked === value} onChange={() => setter(value)} />
        <span style={{ marginLeft: '10px' }}>
          {label}
        </span>
      </label>
    </div>
  );
};

const TableCell = styled(MuiTableCell)({
  textAlign: 'left',
  font: 'normal normal normal 14px/18px Source Sans Pro',
  letterSpacing: '0px',
  color: '#152035',
  opacity: 1,
  width: 100,
  maxWidth: 100,
  whiteSpace: "break-spaces",
  verticalAlign: "text-top",
  borderBottomWidth: '2px'

});

const TableCellHeading = styled(TableCell)({
  font: 'normal normal 600 16px/22px Source Sans Pro',
  textAlign: 'left'
});


//authentication constants
const AUTHN = "authn";
const WEBAUTHN = "webauthn";
const CMLA = "cmla";
const WEBAUTHN_ACCESS_KEY = "webauthnaccess";


export default function Configurator(props) {
  const { t } = useTranslation();
  const { isDomainIntegration, authenticationSettingsDto, shouldShowAccessKey} = props;
  const { state, dispatch } = React.useContext(context);
  var showAccessKey = shouldShowAccessKey !== undefined ? shouldShowAccessKey : true
  var authConfig = {
    WEBAUTHN_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.WEBAUTHN_ENABLED : true,
    WEBAUTHN_MD_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.WEBAUTHN_MD_ENABLED : true,
    AUTHN_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.AUTHN_ENABLED : true,
    // CMLA_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.CMLA_ENABLED : false
    CMLA_ENABLED : false, // CMLA DISABLED FOR NOW
    WEBAUTHN_ACCESS_KEY_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.WEBAUTHN_ACCESS_KEY_ENABLED : true
  };

  let initialChange = true;

  useEffect(() => {
    if (authenticationSettingsDto !== undefined && initialChange) {
      initialChange = false;
      var setMethod = AUTHN;
      var setFallbackMethod = undefined;
      if (authenticationSettingsDto !== undefined) {
        if (!authenticationSettingsDto.AUTHN_ENABLED) {
          if (authenticationSettingsDto.WEBAUTHN_ENABLED) {
            setMethod = WEBAUTHN;
            setFallbackMethod = authenticationSettingsDto.CMLA_ENABLED ? t('configurator.cmla') : t('configurator.none');
          } else if (authenticationSettingsDto.CMLA_ENABLED) {
            setMethod = CMLA;
            setFallbackMethod = undefined;
          } else if (authenticationSettingsDto.WEBAUTHN_ACCESS_KEY_ENABLED && !authenticationSettingsDto.CLIENT_CERTIFICATE_ENABLED) {
            setMethod = WEBAUTHN_ACCESS_KEY;
            setFallbackMethod = undefined;
          } else {
            setMethod = WEBAUTHN;
            setFallbackMethod = undefined;
          }
        }
      }
      if (state.method !== setMethod || state.fallbackMethod !== setFallbackMethod) {
        dispatch({ type: ACTION_TYPE.METHOD, payload: { method: setMethod, fallbackMethod: setFallbackMethod } });
      }
    } 

  }, [])




  function updateStatus(loginMethod, typeOfMethod) {

    let setMethod = typeOfMethod === "login" ? loginMethod : WEBAUTHN;
    let setFallbackMethod = typeOfMethod === "fallback" ? loginMethod : (loginMethod === WEBAUTHN ? "none" : undefined);
    dispatch({ type: ACTION_TYPE.METHOD, payload: { method: setMethod, fallbackMethod: setFallbackMethod } });

    if (typeOfMethod === "fallback") {
      authConfig.CMLA_ENABLED = t('configurator.cmla') === loginMethod;
      authConfig.WEBAUTHN_ENABLED = true;
      authConfig.AUTHN_ENABLED = false;
      authConfig.WEBAUTHN_MD_ENABLED = false;
    } else if (typeOfMethod === "login") {
      if (AUTHN === loginMethod) {
        authConfig.AUTHN_ENABLED = true;
        authConfig.WEBAUTHN_ENABLED = true;
        authConfig.WEBAUTHN_MD_ENABLED = true;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = false;
      } else if (WEBAUTHN === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = true;
        authConfig.WEBAUTHN_MD_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = false;
      } else if (CMLA === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = false;
        authConfig.WEBAUTHN_MD_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = true;
      } else if (WEBAUTHN_ACCESS_KEY === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = true;
        authConfig.CMLA_ENABLED = false;
      }
    }
    dispatch({ type: ACTION_TYPE.INTEGRATION_AUTH_CONFIG, payload: authConfig });
  }

  let methods = [
    {
      "loginMethod": t('configurator.webauthn'),
     // "fallbackLoginMethods": isDomainIntegration !== 'true' ? [t('configurator.cmla'), t('configurator.none')] : [t('configurator.none')], CMLA DISABLED FOR NOW
      "numberOfDevices": t('configurator.devices_webauthn').split('\n').map((str, index) =>
        <p>
          {index === 0 && str}
          {index === 1 && <span style={{ color: 'gray', marginLeft: '-5px' }}> {str} </span>}
        </p>
      ),
      "formValue": "webauthn"
    },
    {
      "loginMethod":
        <ul component='span' style={{ listStyleType: "none", transform: 'translate(-10px, -60%)' }}>
          {t('configurator.authn').split('\n').map((str) => <li>{str}</li>)}
        </ul>
      ,
      "numberOfDevices": t('configurator.devices_all_methods').split('\n').map((str, index) => <p>
        {index == 0 && str}
        {index == 1 && <b>{str.substring(0,str.indexOf(":"))}</b>}
        {index == 1 && str.substring(str.indexOf(":"),str.length)}
      </p>),
      "formValue": "authn"
    }//,
    // isDomainIntegration !== 'true' ?
    //   {
    //     "loginMethod": t('configurator.cmla'),
    //     "fallbackLoginMethods": [t('none')],
    //     "numberOfDevices": t('none'),
    //     "formValue": "cmla"
    //   } : undefined              CMLA DISABLED FOR NOW
  ]
  if (showAccessKey &&
    (authenticationSettingsDto === undefined || (authenticationSettingsDto !== undefined && !authenticationSettingsDto.CLIENT_CERTIFICATE_ENABLED))) {
    methods.push({
      "loginMethod": t('configurator.webauthn_access_key'),
      "numberOfDevices": t('configurator.devices_webauthn_access_key').split('\n').map((str, index) =>
        <p>
          {index === 0 && str}
          {index === 1 && <span style={{ color: 'gray', marginLeft: '-5px' }}> {str} </span>}
        </p>
      ),
      "formValue": "webauthnaccess"
    })
  }


  //filter undefined values
  methods = methods.filter(function (element) {
    return element !== undefined;
  });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow >
            <TableCellHeading>
              <div>
                {t('configurator.login_method')}
              </div>
            </TableCellHeading>

            <TableCellHeading>
              <div>
                {t('configurator.number_of_devices')}
              </div>
            </TableCellHeading>
          </TableRow>
        </TableHead>

        <TableBody>
          {methods.map((methodObject, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                <p>
                  <RadioInput
                    sx={{
                      color: "#293DC7",
                      '&.Mui-checked': {
                        color: "#293DC7",
                      },
                    }}
                    element="div"
                    label={methodObject.loginMethod}
                    value={methodObject.formValue}
                    checked={state.method}
                    setter={() => updateStatus(methodObject.formValue, "login")} />
                </p>
              </TableCell>

              <TableCell>
                <Typography>
                  {methodObject.numberOfDevices}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
}