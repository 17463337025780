import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDivider from '@mui/material/Divider';
export default function FederationOptionRow(props) {

    const {isDividerShown, contentText, isFunctionalityShown, contentFunctionality, isEdit} = props;
    
    const Divider = styled(MuiDivider)({
        borderLeft:'5px solid', 
        borderColor: '#293DC7', 
        borderRight: 'none', 
        borderTop: 'none', 
        borderBottom: 'none', 
        height: '1px',
        width: isEdit ? '75%' : '55%',
        backgroundColor: 'black',
        marginBottom: '10px'
    });
    

    return (
        <div>
            { isDividerShown && <Divider/> }
            <div style={{display: 'flex'}}>
                <div style={{width: 200}}>
                    {contentText}
                </div>
                <div style={{marginLeft: 50}}>
                    {isFunctionalityShown && contentFunctionality}
                </div>
            </div>
            <Divider/>
        </div>
    );
}
