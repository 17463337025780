import React, { useEffect, useState, useRef } from "react";
import {Button, InverseButton, ExitButton} from './CustomUI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Typography } from "@mui/material";
import {context, ACTION_TYPE} from "../State";


export default function CustomDialog (props) {
  const {open, onConfirm, onCancel, title, subtitle, confirmBtnText, cancelBtnText, disableConfirmBtn, isCentered, iconUrl, singleButton, hideCloseButton, email, id} = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const {dispatch} = React.useContext(context);

  let textAlignClass = (isCentered === true || iconUrl !== undefined || singleButton === true) ? "center": "left";
  let widthClass = isCentered === true ? "70%": "75%";
  let widthTitle = iconUrl === undefined ? 300 : '50%';
  let marginRightButton = (iconUrl !== undefined || singleButton === true) ? 0 : 20;

  if (iconUrl !== undefined) {
    widthClass = "65%";
  }
  
  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_UPGRADE_PLAN, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DOWNLOAD_REPORT, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE, payload:false})
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_DEVICE, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_USER, payload:false});
    if (onCancel !== undefined) {
      onCancel();
    }
  };

  return (
    <div>
      <Dialog
        id = {id}
        open={openDialog}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{style : {
          background: '#FFFFFF 0% 0% no-repeat padding-box', 
          boxShadow: '0px 3px 6px #00000029',
          borderRadius: '8px',
          opacity: 1
        },
      }}
      >
        <DialogTitle id="alert-dialog-title" style={{textAlign: textAlignClass}}>
            {!hideCloseButton &&
            <IconButton onClick={handleDialogClose} style={{float: 'right'}}>
                <ExitButton/>
            </IconButton>
            }
          {iconUrl !== undefined && <div style={{textAlign: 'center'}}> <br/> <img src={iconUrl}/></div>}
          <br/>
          <Typography component={'div'} variant="subtitle1" color="textPrimary" style={{width: widthTitle, margin:'auto'}}>
                {title}
          </Typography>
        </DialogTitle>
        <DialogContent style={{textAlign: textAlignClass}}>
          <DialogContentText component={'div'} id="alert-dialog-description" style={{marginTop: 12, width: 400}}>
              <div style={{width: widthClass, margin: 'auto'}}>
                {subtitle}
              </div>
              {email !== undefined && 
                <div>
                  <br/>
                  <Typography component={'span'} variant="h4"> 
                    <div style={{fontWeight: "bold"}}>
                        {email}
                    </div>
                  </Typography>
                </div>
              }
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: textAlignClass, margin: 'auto'}}>
          <Button onClick={onConfirm} style={{marginRight: marginRightButton}}>{confirmBtnText}</Button>
          { (singleButton === 'false' || singleButton === undefined) && 
            <InverseButton onClick={handleDialogClose}>
                {cancelBtnText}
            </InverseButton>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}