import React from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {styled, Typography} from "@mui/material";
import Box from '@mui/material/Box';

const Snackbar = styled(MuiSnackbar)(({theme}) => ({
  boxShadow: '0px 3px 6px #00000060',
  opacity: 1
}));

const Alert = styled(MuiAlert)(({theme}) => ({
  font: 'normal normal normal 14px/20px Source Sans Pro',
  paddingLeft: '26px',
  maxWidth: '500px'
}))

export default function CustomSnackbar(props) {
    const {open, onCancel, type, text, buttonText, vertical, horizontal, showIcon, autoHide, isDomainFederation} = props;
    let icon = "";
    let color = "";
    let isCustom = false;
    switch(type) {
        case 'success': 
            icon = showIcon === 'true' ? <CheckIcon fontSize="inherit" style={{height:'22px', width: '22px'}}/> : false
            color = "#6DDD9C"
            isCustom = false;
            break;
        case 'error': 
            icon = showIcon === 'true' ? <CloseIcon fontSize="inherit" style={{height:'22px', width: '22px'}} /> : false
            color = "#C72941"
            isCustom = false;
            break;
        case 'info':
            icon = false;
            color = "#293DC7"
            isCustom = false;
            break;
        case 'recommendation':
            icon = false;
            color = "#FFFFFF"
            isCustom = true;
            break;
    }
    
    return (
      <div>
        <Snackbar
        onClose={onCancel}
        open={open} 
        autoHideDuration={autoHide === 'false' ? null : 5000}
        anchorOrigin={{vertical, horizontal}}
        sx={{position: isDomainFederation === true ? 'absolute' : 'fixed'}}
        >
          <div>
          {isCustom && 
          <Alert 
            sx={{
              bgcolor: color,
              color:'black',
              '@media (min-width:2560px) ': {
                    
                maxWidth:'800px'
              }, 
              
            }} 
            icon={icon} 
            elevation={6} 
            variant="filled" 
            >
              <Box sx={{display:'flex', 
              '@media (min-width:1366px) and (max-width:1919px)': {
                    
                maxWidth:'25vw', margin:'1%'
              }, 
              '@media (min-width:1920px) and (max-width: 2559px) ': {
                    
                margin:'2%'
              },  
              '@media (min-width:2560px) ': {
                    
                margin:'2%',
              }, 
            }}>
                <Typography variant="h4" sx={{
                   '@media (min-width:1366px) and (max-width:1919px)': {
                       fontSize:11,
                      
                   }, 
                   '@media (min-width:2560px) ': {
                       fontSize: 24,
                       
                   }, 
                }}>
                {text}
                </Typography>
                
                <Box  style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                     
                  }} sx={{width:'30%'}}>
                  <Button
                    color="inherit" 
                    size="small"
                    onClick={onCancel}
                   
                    sx={{width:'50%', height:'50%', justifyContent:'center', alignItems:'center'}}
                    >
                      <Typography variant="h4"sx={{
                   '@media (min-width:1366px) and (max-width:1919px)': {
                    
                       fontSize:11,
                   }, 
                   '@media (min-width:2560px) ': {
                      
                    fontSize: 24,
                   }, 
                }} >
                        {buttonText}
                      </Typography>
                  </Button>
                </Box>
                
              </Box>
            
            
          </Alert>}
          {!isCustom && 
          <Alert 
            sx={{
              bgcolor: color
            }} 
            icon={icon} 
            elevation={6} 
            variant="filled" 
            severity={type} 
            action={
            <Button 
              color="inherit" 
              size="small" 
              onClick={onCancel}>
                {buttonText}
            </Button>
            }>
            {text}
          </Alert>}   
          </div>
          
        </Snackbar>
      </div>
    );
  }