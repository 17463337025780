import React, { useEffect, useState, useRef } from "react";
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { useTranslation } from "react-i18next";


export default function SelectItemList(props) {
  const { open, onClose, items, anchorEl, isViewIntegration, isFromSearchDialog} = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  let manageUsersActions = items.filter((function (el) {
    return el.type == "user"
  }));

  let manageIntegrationsActions = items.filter((function (el) {
    return el.type == "integration"
  }));

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    onClose();
  };


  return (

    <div>
      <Menu
        anchorEl={anchorEl}
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '8px 0px 8px 8px',
            opacity: 1,
            padding: 10
          },
          elevation: 0,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleDialogClose}

      >
        {isViewIntegration == undefined && !isFromSearchDialog  && <div>
          {/* If OIDC integration there are 2 elements in array so we don't need Manage users heading */}
          {items.length > 2 &&
            <div style={{
              textAlign: "left",
              font: 'normal normal bold 1.1vw/1vh Source Sans Pro',
              letterSpacing: "0px",
              color: "#152035",
              opacity: 1,
              marginBottom: '10%',
              paddingLeft: '6%'
            }}>
              {t('select_menu.manage_users_heading')}
            </div>
          }
          {manageUsersActions.map((item, index) => {
            return (
              <div>
                <MenuItem disabled={item.isDisabled === 'true'} onClick={item.func} >
                  {item.imageUrl !== undefined &&
                    <ListItemIcon sx={{ paddingRight:'7%', width:'18%'
                    }}>
                      <img src={item.imageUrl} width='100%' height='100%'/>
                    </ListItemIcon>
                  }
                  <ListItemText
                    disableTypography 
                    primary={
                    <Typography type="body2"
                    style={{ textAlign: 'left', 
                    font: 'normal normal normal 1.1vw/1vh Source Sans Pro',
                    letterSpacing: '0px', color: '#152035', opacity: 1 }}
                    >{item.text}</Typography>} />
                </MenuItem>
                {index !== (manageUsersActions.length) && <Divider />}
              </div>
            );
          })}
          <div style={{
            textAlign: "left",
            font: 'normal normal bold 1.1vw/1vh Source Sans Pro',
            letterSpacing: "0px", color: "#152035",
            opacity: 1,
            marginBottom: '10%',
            marginTop: '20px',
            paddingLeft: '6%'
          }}>
            {t('select_menu.manage_integrations_heading')}
          </div>
          {manageIntegrationsActions.map((item, index) => {
            return (
              <div>
                <MenuItem disabled={item.isDisabled === 'true'} onClick={item.func}>
                  {item.imageUrl !== undefined &&
                    <ListItemIcon sx={{paddingRight:'7%', width:'18%'
                   }}>
                      <img src={item.imageUrl} width='100%' height='100%'/>
                    </ListItemIcon>
                  }
                  <ListItemText
                    disableTypography
                    primary={<Typography type="body2" style={{ textAlign: 'left', 
                      font: 'normal normal normal 1.1vw/1vh Source Sans Pro', 
                      letterSpacing: '0px', color: '#152035', opacity: 1 }}>{item.text}</Typography>} />
                </MenuItem>
                {index !== (manageIntegrationsActions.length - 1) && <Divider />}
              </div>
            );
          })}
        </div>
        }
        {isViewIntegration &&
          <div>
            {manageIntegrationsActions.map((item, index) => {
              return (
                <div>
                  <MenuItem disabled={item.isDisabled === 'true'} onClick={item.func}>
                    {item.imageUrl !== undefined &&
                      <ListItemIcon>
                        <img src={item.imageUrl} />
                      </ListItemIcon>
                    }
                    <ListItemText sx={{width:'50%'}}
                      disableTypography
                      primary={<Typography type="body2" style={{ textAlign: 'left', font: 'normal normal normal 14px/20px Source Sans Pro', letterSpacing: '0px', color: '#152035', opacity: 1 }}>{item.text}</Typography>} />
                  </MenuItem>
                  {index !== (manageIntegrationsActions.length - 1) && <Divider />}
                </div>
              );
            })}
          </div>
        }
        {isFromSearchDialog &&
          <div>
            {items.map((item, index) => {
              return (
                <div>
                   <MenuItem disabled={item.isDisabled === 'true'} onClick={item.func}>
                    {item.imageUrl !== undefined &&
                      <ListItemIcon>
                        <img src={item.imageUrl} />
                      </ListItemIcon>
                    }
                    <ListItemText sx={{width:'50%'}}
                      disableTypography
                      primary={<Typography type="body2" style={{ textAlign: 'left', font: 'normal normal normal 14px/20px Source Sans Pro', letterSpacing: '0px', color: '#152035', opacity: 1 }}>{item.text}</Typography>} />
                  </MenuItem>
                  {index !== (items.length - 1) && <Divider />}
                </div>
              )
            })

            }
          </div>

        }
      </Menu>
    </div>);
}