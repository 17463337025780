import React from 'react';
import {context} from "../State";
import Typography from "@mui/material/Typography/Typography";
import MuiDivider from '@mui/material/Divider';
import {styled} from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function IntegrationDetailsSectionItem (props) {

    const {heading, content, divide, isEditModeOn, sectionHeading, checked, checkedSiem, handleChange, scimEnabled} = props;
    const {state} = React.useContext(context);
    const { t } = useTranslation();

    let displayStyle= isEditModeOn === true ? 'grid': 'initial';
    let isHidden = ((sectionHeading === t('integration_view.scim_provisioning')) && (checked === false)) || ((checked === true) && (scimEnabled === true) && (heading === "Secret"));
    let isNoteShown = (checked === true) && (scimEnabled === false) && (heading === "Secret")

    const Divider = styled(MuiDivider) (({theme}) => ({
        marginBottom: 22,
        width: '80%',
        marginTop: 20
    }));

    return (
        <div >
            <div style={{display: displayStyle, marginBottom: isEditModeOn ? 20:0, visibility : isHidden ? "hidden" : "visible"}}>
                <Typography variant="headingTextView" component="div" gutterBottom style={{ display: 'inline-block', width: '30%' }}>
                    <div>
                        {heading}
                    </div>
                </Typography>   
                {content}
                {isNoteShown && t('integration_view.scim_secret_note')}
            </div>
            {divide && <Divider/>}
        </div>
    );
}