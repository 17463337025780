import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next).init({
    detection: { order: ["navigator", "path"] },
    fallbackLng: 'en',
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
        de: {
            translations: require('./locales/de/translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['en', 'de'];

export default i18n;