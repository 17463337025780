import * as React from 'react';
import { useEffect } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { styled } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Fade from '@mui/material/Fade';
import { useTheme } from "@mui/styles";
import { publicUrl } from "../App";
import { context, ACTION_TYPE } from "../State";
import Api from "../helpers/service";
import { log, tryLocalStorageSetItem } from '../helpers/utils'
import { InverseButton } from './CustomUI';
import LogoutComp from '../img/logout-icon-component';
import CustomSnackbar from './Snackbar';
import { useTranslation } from 'react-i18next';
import { getCurrentBrowser } from '../helpers/utils';
import { useLocation } from 'react-router-dom';

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: 'none',
}));

const Logo = styled('img')({
    width: '14vh',
    alignSelf: 'center',
    verticalAlign: 'middle',
    paddingTop: '40%',
    paddingLeft: '40%'
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    height: 80,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        height: '8vh'
    },
}));


function ElevationScroll(props) {
    const { children } = props;
    return React.cloneElement(children, {
        elevation: useScrollTrigger() ? 4 : 0,
    });
}


function FadeInOnScroll(props) {
    const trigger = useScrollTrigger();
    const theme = useTheme();
    return (
        <Fade in={trigger}>
            <Typography
                variant="h2"
                noWrap
                component="div"
                sx={{fontSize:'3vw', flexGrow: 1, alignSelf: 'center', marginLeft: '9vw' }}
            >
                {props.title}
            </Typography>
        </Fade>
    );
}

export default function AppBar(props) {
    const { onLogout, email } = props;
    const { state, dispatch } = React.useContext(context);
    const { t } = useTranslation();
    const location = useLocation();

    const showResolutionRecommendationSnackFunction = ()=>{
        dispatch({ type: ACTION_TYPE.SHOW_RESOLUTION_RECOMMENDATION_SNACK, payload: true })
    }
    const handleResolutionRecommendationSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: ACTION_TYPE.SHOW_RESOLUTION_RECOMMENDATION_SNACK, payload: false });
    }

    useEffect(()=>{
        if( getCurrentBrowser() != ("Google Chrome or Chromium") && (location.pathname=== "/login")) 
            showResolutionRecommendationSnackFunction();
    },[])

    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <StyledAppBar sx={{ backgroundColor: location.pathname === "/setup" ? '#F7F7F7' : '#FFFFFF'}} >
                    <StyledToolbar disableGutters>
                        <Box sx={{ width: '10vh', textAlign: 'center', alignSelf: 'center' }} >
                            <Logo src={publicUrl + "/company-logo.svg"} alt="Logo" />
                        </Box>
                        <FadeInOnScroll {...props} />
                        { !onLogout && (location.pathname=== "/login") &&
                            <CustomSnackbar open={state.showResolutionRecommendationSnack} onCancel={handleResolutionRecommendationSnackClose} type={'recommendation'} vertical={'top'} horizontal={'center'} text={t('login.recommended_resolutions')} buttonText={t('notification.okay')} showIcon='true' autoHide='false' />
                        }
                        
                        { !onLogout && ((location.pathname=== "/login") || (location.pathname=== "/registration")) &&
                            <div style={{display: 'flex', paddingTop: '4vh', paddingRight: '3vw'}}>
                                <InverseButton sx={{
                                    '@media (min-width:1366px) and (max-width:1919px)': {
                                        width:'0.1vw', height:'4.5vh'
                                    },
                                    '@media (min-width:1920px)':{
                                        width:'4vw', height:'4.5vh'
                                    },
                                    borderRadius:10}} onClick={()=>{ window.open("https://getidee.atlassian.net/servicedesk/customer/portal/1", "_blank");}}>
                                    <Typography variant="h3" sx={{
                                                                    fontWeight: 400,
                                                                    '@media (max-width:1365px)': {
                                                                        fontSize: 13,
                                                                    },
                                                                    '@media (min-width:1366px) and (max-width:1919px)': {
                                                                        fontSize: 13,
                                                                    },
                                                                    '@media (min-width:1920px) and (max-width:2559px)': {
                                                                        fontSize: 18,
                                                                    },
                                                                    '@media (min-width:2560px)': {
                                                                        fontSize: 24,
                                                                    },
                                                                    }}> 
                                    {t('login.get_help')}
                                    </Typography>
                                </InverseButton>
                            </div>
                        }

                        {onLogout &&
                            <div style={{display: 'flex', paddingRight: '2vw'}}>
                                <Typography variant="h5" style={{
                                    font: 'normal normal 0.8vw/1.4vh Source Sans Pro',
                                    letterSpacing: '0.34px',
                                    color: '#000000DE',
                                    opacity: 1,
                                    marginLeft: '1vw',
                                    transform: 'translateY(25%)',
                                    paddingTop: '2vh',
                                    alignSelf: 'center'
                              
                                }}>
                                    {email}
                                </Typography>
                                <IconButton
                                    size="medium"
                                    aria-label="logout"
                                    edge="end"
                                    color="inherit"
                                    style={{ justifyContent: 'flex-start', paddingLeft: '3vw', paddingRight: '2vw', paddingTop: '4vh', width:'100%', height:'100%' }}
                                    onClick={onLogout}
                                    disableRipple
                                >
                                    
                                    <LogoutComp size={'3vh'}/>
                                    
                                    <Typography variant="h5" style={{
                                        font: 'normal normal bold 0.9vw/1.4vh Source Sans Pro',
                                        letterSpacing: '0.34px',
                                        color: '#000000DE',
                                        opacity: 1
                                    }}>
                                        Logout
                                    </Typography>
                                </IconButton>
                            </div>
                        }
                    </StyledToolbar>
                </StyledAppBar>
            </ElevationScroll>
            <Toolbar />
        </React.Fragment>
    );
}
