import React from 'react';
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import Grid from '@mui/material/Grid';
import {configManager} from "../App";

export default function BasicLayout(props) {
    const { content, hasFooter, isSetup } = props;
    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems='center'
            sx={{minHeight: '100vh', backgroundColor: isSetup === true ? '#F7F7F7' : "#FFFFF"}}
        >
            <Grid item xs={12}>
                <AppBar />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={'max-width'} style={{zIndex: 1200, alignContent: 'flex-start'}}>
                {content}
            </Grid>
            <Grid item xs={12}>
                {hasFooter &&
                    <Footer iosLink={configManager.iosLink} androidLink={configManager.androidLink}/>
                }
            </Grid>
        </Grid>
    );
}