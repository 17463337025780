import React from 'react';
import { federateDomainTokenRequest } from '../config/authConfig';
import GrantAccessButtonToggle from './GrantAccessButtonToggle';
import FederationOptionRow from './FederationOptionRow';
import StatusText from './StatusComponent';
import { Button, ActionButton, ErrorSpan } from './CustomUI';
import CustomSnackbar from './Snackbar';
import arrowIcon from '../img/arrow.svg'
import { useTranslation } from "react-i18next";
import GraphApi from '../helpers/graph';
import { context } from "../State";
import DropDown from "./DropDown";
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from '@mui/material';
import Api from '../helpers/service';
import { isValidDomain, lowerAndTrimEmail } from '../helpers/utils';
import { useEffect } from 'react';


var usersWithoutImmutableIds = [];
var immutableIdsSetSucess;


export default function FederationComponent(props) {

    const { domainInputRef, isTestIntegration, showGrantAccessButton, setShowGrantAccessButton, adminEmail, setAdminEmail,
            domain, isEdit, disabledFederateBtn, disabledManagedBtn, setDisabledFederateBtn, setDisabledManagedBtn, disabled, setDisabled , serverName} = props;

    const [error, setError] = React.useState("");
    const [isFederated, setIsFederated] = React.useState(false);
    const [isNotRoot, setIsNotRoot] = React.useState(false);
    const [isPrimary, setIsPrimary] = React.useState(false);
    const [showSnackIsHybrid, setShowSnackIsHybrid] = React.useState(false);
    const [immutableIdsNeeded, setImmutableIdsNeeded] = React.useState(false);
    const [domainMismatch, setDomainMismatch] = React.useState(false);
    const [federatedText, setFederatedText] = React.useState("");
    const [rootText, setRootText] = React.useState("");
    const [primaryText, setPrimaryText] = React.useState("");
    const [immutableIdsText, setImmutableIdsText] = React.useState("");
    const [domainList, setDomainList] = React.useState([]);
    const [showSnackFederated, setShowSnackFederated] = React.useState(false);
    const [showSnackManaged, setShowSnackManaged] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);
    const [inDatabase, setInDatabase] = React.useState(false);
    const [isDomainValid, setIsDomainValid] = React.useState(true);
    const { state } = React.useContext(context);


    const { t } = useTranslation();
    const graphApi = new GraphApi();
    const api = new Api();
    graphApi.init(state.graphApiAccessToken);

    useEffect(() => {
        disableButtons(domain);
    }, [disabled]);

    /*  From IntegrationView domain is sent as state and from AddIntegration page domain is sent as reference
        let domain = isEdit ? domainRef : domainRef.current; */

    async function disableButtons(domainRef) {
        if(isEdit) {
            let domain = domainRef;
            const authenticationType = await graphApi.isFederated(domain);
            setDisabledFederateBtn(authenticationType === "Federated" || disabled);
            setDisabledManagedBtn(authenticationType === "Managed" || disabled);
        } else {
            setDisabledFederateBtn(disabled);
        }

    }

    const focusDomainInput = (event) => {
        domainInputRef.current.focus();
    };

    async function makeRoot(event, domainRef) {
        let domain = isEdit ? domainRef : domainRef.current;
        await graphApi.promoteDomain(domain, setIsRootCallback, errorSetter);
    }

    const errorSetter = (error) => {
        setError(error);
    }

    const setImmutableIdsError = (error) => {
        setError(error);
        immutableIdsSetSucess = false;
    }

    function filterUsers(item) {
        return item.onPremisesImmutableId === null;
    }

    function setImmutableIdsCallback() {
        immutableIdsSetSucess = true;
    }

    function setIsRootCallback() {
        setIsNotRoot(false);
        setError("");
        setRootText(t("domain_federation.domain_is_root"));
    }

    function setIsFederatedCallback() {
        setError("");
        setShowSnackFederated(true);
        if (isEdit) {
            setDisabledFederateBtn(true);
            setDisabledManagedBtn(false);
        }
    }

    function setIsManagedCallback() {
        setError("");
        setFederatedText("");
        setRootText("");
        setPrimaryText("");
        setImmutableIdsText("");
        setIsVisible(false);
        setShowSnackManaged(true);
        setDisabledManagedBtn(true);
        setDisabledFederateBtn(false);
    }

    function resetState() {
        setFederatedText("");
        setRootText("");
        setPrimaryText("");
        setImmutableIdsText("");
        setError("");
        setIsDomainValid(true);
        setIsFederated(false);
        setInDatabase(false);
        setDomainMismatch(false);
        setIsNotRoot(false);
        setIsPrimary(false);
        setImmutableIdsNeeded(false);
    }


    const handleCloseSnackDomainFederated = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowSnackFederated(false);
    }

    const handleCloseSnackDomainManaged = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowSnackManaged(false);
    }

    const handleCloseSnackDomainHybridSetup = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowSnackIsHybrid(false);
    }

    async function checkUsersForImmutableIds(domain) {
        var usersForDomainTmp = [];
        let usersWithoutImmutableIdsTmp = [];

        await graphApi.getUsersForDomain(domain, usersForDomainTmp).then(() => {
            usersWithoutImmutableIdsTmp = usersForDomainTmp.filter(filterUsers);
            if (usersWithoutImmutableIdsTmp.length > 0) {
                setImmutableIdsNeeded(true);
                setImmutableIdsText(t("domain_federation.immutable_ids_not_set"));
            } else {
                setImmutableIdsNeeded(false);
                setImmutableIdsText(t("domain_federation.immutable_ids_set"));
            }

            usersWithoutImmutableIds = usersWithoutImmutableIdsTmp;
        });

    }

    async function federateDomain(event, domainRef) {

        resetState();

        let domain = isEdit ? domainRef : domainRef.current;
        
        setIsVisible(true);

        const isHybridSetup = await graphApi.checkIfHybridSetup();

        setShowSnackIsHybrid(isHybridSetup);

        if(!isValidDomain(domain)) {
            setIsDomainValid(false);
            setFederatedText(t("add_integration.errors.not_valid_domain"));
            return;
        }

        if (isEdit !== true) {
            const isInDatabase = await api.checkDomain(domain);
            if(isInDatabase) {
                setInDatabase(true);
                setFederatedText(t("domain_federation.domain_on_idee"));
                return;
            }
        }
        
        const domainInTenant = await graphApi.checkIfEnteredDomainMatch(domain);
        if (domainInTenant) {
            setDomainMismatch(false);
        } else {
            setDomainMismatch(true);
            setFederatedText(t("domain_federation.domain_not_on_tenant"));
            return;
        }

        const federated = await graphApi.isFederated(domain);
        if (federated === "Federated") {
            setFederatedText(t("domain_federation.domain_already_federated"));
            setIsFederated(true);
            return;
        } else {
            setFederatedText(t("domain_federation.domain_not_federated"));
            setIsFederated(false);
        }

        const root = await graphApi.isRoot(domain)
        if (root) {
            setIsNotRoot(false);
            setRootText(t("domain_federation.domain_is_root"));
        } else {
            setIsNotRoot(true);
            setRootText(t("domain_federation.domain_is_not_root"));
        }


        const primary = await graphApi.isPrimary(domain);
        if (primary) {
            setIsPrimary(true);
            setPrimaryText(t("domain_federation.domain_is_primary"));
        } else {
            setIsPrimary(false);
            setPrimaryText(t("domain_federation.domain_is_not_primary"));
        }

        if (primary) {
            var domainListTmp = [];
            await graphApi.getManagedDomains(domainListTmp);

            if (domainListTmp !== undefined) {
                setDomainList(domainListTmp);
            } else {
                setDomainList(null);
            }
        }

        if (!immutableIdsSetSucess) {
            await checkUsersForImmutableIds(domain);
        } else {
            setImmutableIdsNeeded(false);
            setImmutableIdsText(t("domain_federation.immutable_ids_set"));
        }

        const setImmutableIds = immutableIdsSetSucess ? false : usersWithoutImmutableIds.length > 0;

        if (federated !== "Federated" && root && !primary && !setImmutableIds && !domainMismatch && isDomainValid ) {
            await graphApi.promoteToFederated(domain, serverName, setIsFederatedCallback, errorSetter);
            immutableIdsSetSucess = false;
        }

    }

    async function setImmutableIds(event) {
        for (let i = 0; i < usersWithoutImmutableIds.length; i++) {
            let email = lowerAndTrimEmail(usersWithoutImmutableIds[i].userPrincipalName);
            await graphApi.updateImmutableIdForUser(email, setImmutableIdsCallback, setImmutableIdsError);
            if (!immutableIdsSetSucess) {
                return;
            }
        }
        setImmutableIdsNeeded(false);
        setError("");
        setImmutableIdsText(t("domain_federation.immutable_ids_set"));
    }

    async function promoteToManaged(event, domainRef) {
        let domain = isEdit ? domainRef : domainRef.current;
        await graphApi.promoteToManaged(domain, setIsManagedCallback, errorSetter);
    }



    return (
        <div>
            <div style={{ whiteSpace: 'normal', fontWeight: 600, marginBottom: 20 }}>{t('create_azure_user.grant_access_heading')}</div>
            <GrantAccessButtonToggle showGrantAccessButton={showGrantAccessButton} setShowGrantAccessButton={setShowGrantAccessButton} adminEmail={adminEmail} setAdminEmail={setAdminEmail} configTokenRequest={federateDomainTokenRequest} setDisabled={setDisabled} />
            <div>
                <div style={{ whiteSpace: 'normal', fontWeight: 600 }}>{t("domain_federation.switch")}</div>
                <span>
                <Button style={{ marginTop: 20, marginBottom: 10 }} onClick={event => federateDomain(event, domain)} disabled={disabledFederateBtn}> {t("domain_federation.federate_domain_btn")} </Button>
                <Button style={{ marginTop: 20, marginBottom: 10, marginLeft: 40, visibility: isEdit ? 'visible' : 'hidden' }} onClick={event => promoteToManaged(event, domain)} disabled={disabledManagedBtn} > {t("domain_federation.manage_domain_btn")} </Button>
                </span>
                <br></br>
                <Typography variant='statusErrorTextView' component='span'>
                    <ErrorSpan style={{marginLeft: -5}}> {error !== "" && <CancelIcon style={{ color: "#C72941", marginBottom: -4 }} fontSize="small" />} {error} </ErrorSpan>
                </Typography>
            </div>
            <div style={{ marginTop: 34, visibility: isVisible ? 'visible' : 'hidden'}}>
                {isTestIntegration === true && <FederationOptionRow isDividerShown={true} contentText={<StatusText text={""} isError={false} />} isFunctionalityShown={true} contentFunctionality={null} isEdit={isEdit} />}
                <FederationOptionRow isDividerShown={isTestIntegration !== true} contentText={<StatusText text={federatedText} isError={isFederated || domainMismatch || inDatabase || !isDomainValid} />} isFunctionalityShown={(isFederated || domainMismatch || inDatabase || !isDomainValid) && isEdit !== true} contentFunctionality={<ActionButton onClick={focusDomainInput}> {t("domain_federation.change_domain_btn")} <img src={arrowIcon} /> </ActionButton>} isEdit={isEdit} />
                <FederationOptionRow isDividerShown={false} contentText={<StatusText text={rootText} isError={isNotRoot} />} isFunctionalityShown={isNotRoot} contentFunctionality={<ActionButton onClick={event => makeRoot(event, domain)}> {t("domain_federation.make_root_btn")} </ActionButton>} isEdit={isEdit} />
                <FederationOptionRow isDividerShown={false} contentText={<StatusText text={primaryText} isError={isPrimary} />} isFunctionalityShown={isPrimary} contentFunctionality={<DropDown items={domainList} currentDomain={isEdit ? domain : domain.current} setError={setError} setIsPrimary={setIsPrimary} setPrimaryText={setPrimaryText}/>} isEdit={isEdit}/>
                <FederationOptionRow isDividerShown={false} contentText={<StatusText text={immutableIdsText} isError={immutableIdsNeeded} />} isFunctionalityShown={immutableIdsNeeded} contentFunctionality={<ActionButton onClick={event => setImmutableIds(event)}> {t("domain_federation.set_immutable_ids_btn")} </ActionButton>} isEdit={isEdit} />
            </div>
            <CustomSnackbar open={showSnackFederated} onCancel={handleCloseSnackDomainFederated} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.federated_domain')} buttonText={t('notification.okay')} showIcon={'true'} autoHide={'false'} isDomainFederation={true} />
            <CustomSnackbar open={showSnackManaged} onCancel={handleCloseSnackDomainManaged} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.managed_domain', {domain: domain})} buttonText={t('notification.okay')} showIcon={'true'} autoHide={'false'} isDomainFederation={true} />
            <CustomSnackbar open={showSnackIsHybrid} onCancel={handleCloseSnackDomainHybridSetup} type={'info'} vertical={'bottom'} horizontal={'right'} text={t('domain_federation.hybrid_setup_detected')} buttonText={t('notification.okay')} autoHide={'false'} />
        </div>);
}