import * as React from "react"

const AdminIcon = React.forwardRef(function AdminIcon(props, parentRef) {

    const localRef = React.useRef();

    React.useEffect(() => {
        // Hacking in the "click()" function that Stonly calls
        if (localRef.current) {
            localRef.current.click = (() => {
                props.onClick(new Event("stonlyDummyClick"));
            });
        }
    }, []);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width='4vh' height='4vh' viewBox="-6 -4 30 30" {...props} ref={ref => { parentRef.current = ref; localRef.current = ref; }} >
            <path id="Vereinigungsmenge_5-2" data-name="Vereinigungsmenge 5" d="M1.732,18.42A1.73,1.73,0,0,1,0,16.688v-.571a4.607,4.607,0,0,1,4.6-4.6h1.99a6.227,6.227,0,0,0,5.249,0h1.989a4.607,4.607,0,0,1,4.6,4.6v.571a1.729,1.729,0,0,1-1.731,1.731Zm2.3-13.244a5.185,5.185,0,1,1,5.194,5.175A5.18,5.18,0,0,1,4.034,5.176ZM8.271,7.442l.129.866a.177.177,0,0,0,.165.129H9.891a.177.177,0,0,0,.166-.129l.129-.866a3.584,3.584,0,0,0,.571-.331l.829.331a.175.175,0,0,0,.2-.074l.663-1.123a.172.172,0,0,0-.037-.2l-.737-.534a1.993,1.993,0,0,0,.018-.331c0-.129-.018-.222-.018-.332l.7-.534a.131.131,0,0,0,.036-.2l-.663-1.124a.155.155,0,0,0-.2-.073l-.829.332a1.984,1.984,0,0,0-.571-.332l-.111-.866a.177.177,0,0,0-.166-.129H8.547a.177.177,0,0,0-.166.129l-.129.866a3.519,3.519,0,0,0-.571.332l-.829-.332a.175.175,0,0,0-.2.073L5.987,4.108a.168.168,0,0,0,.037.2l.718.534c0,.129-.018.2-.018.332s.018.221.018.331l-.7.534a.131.131,0,0,0-.036.2l.663,1.123a.155.155,0,0,0,.2.074L7.7,7.11A2.029,2.029,0,0,0,8.271,7.442ZM8.087,5.158A1.142,1.142,0,1,1,9.21,6.318,1.16,1.16,0,0,1,8.087,5.158Z" transform="translate(0 0)" opacity="0.863" />
        </svg>
    )

});

export { AdminIcon };
