import React, { useEffect } from 'react';
import BasicLayout from "../BasicLayout";
import FullScreenStepper from "../../components/FullScreenStepper";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { log, strip, availableServiceProviders } from "../../helpers/utils";
import Box from '@mui/material/Box';
import { Button, ErrorSpan } from '../../components/CustomUI';
import Section from '../../components/Section';
import { context, ACTION_TYPE } from "../../State";
import errorIcon from "../../img/error-icon.svg";
import CustomDialog from '../../components/DialogText';

import { createUserTokenRequest } from '../../config/authConfig';
import { useMsal } from '@azure/msal-react';
import { ResponseType } from '@microsoft/microsoft-graph-client';
import { generate } from '../../helpers/generate'; 
import GrantAccessButtonToggle from '../../components/GrantAccessButtonToggle';
import GraphApi from '../../helpers/graph';


var currentServiceProvider = availableServiceProviders[0];
var integrationName = "";
var firstName = "";
var lastName = "";
var displayName = "";
var userName = "";

var whichTextField = "first";
var defaultDisplayName = true;

function UserDetails(props) {
    
    const {
        showErrorMessageDisplayName, setShowErrorMessageDisplayName, showErrorMessageUsername, setShowErrorMessageUsername,
        errorMessage, setErrorMessage,  showErrorMessageLastName, showErrorMessageFirstName, setShowErrorMessageLastName,
        setShowErrorMessageFirstName, showErrorGraph, setShowErrorGraph
    } = props;

    const [enteredFirstName, setEnteredFirstName] = React.useState(firstName);
    const [enteredLastName , setEnteredLastName] = React.useState(lastName);
    const [enteredDisplayName, setEnteredDisplayName] = React.useState(displayName);
    const [enteredUserName , setEnteredUserName] = React.useState(userName);
   
    const [enteredWhichTextField, setEnteredWhichTextField] = React.useState(whichTextField);
    const [enteredDefaultDisplayName, setEnteredDefaultDisplayName] = React.useState(defaultDisplayName);

    //fields that users enters for Azure
    

    const { state, dispatch } = React.useContext(context);
    const {t} = useTranslation();
    const graphApi = new GraphApi();
    graphApi.init(state.graphApiAccessToken);
    

    React.useEffect(()=>{
        firstName = enteredFirstName;
        lastName = enteredLastName;
        displayName = enteredDisplayName;
        userName = enteredUserName;
        whichTextField = enteredWhichTextField;
        defaultDisplayName = enteredDefaultDisplayName;
    },[])

    const MINIMUM_DISPLAY_NAME_LENGTH = 1;
    const MAXIMUM_DISPLAY_NAME_LENGTH = 256;
    const MINIMUM_FIRST_NAME_LENGTH = 0;
    const MINIMUM_LAST_NAME_LENGTH = 0;
    const MAXIMUM_FIRST_NAME_LENGTH = 64;
    const MAXIMUM_LAST_NAME_LENGTH = 64;

    const validateEmailUsername = React.useMemo(() => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))$/;
        return (username) => re.test(username);
    }, []);

    function refreshValues(){
        setEnteredFirstName(firstName);
        setEnteredLastName(lastName);
        setEnteredDisplayName(displayName);
        setEnteredUserName(userName);
        setEnteredWhichTextField(whichTextField);
        setEnteredDefaultDisplayName(defaultDisplayName);
    }
    function validateFields(){
         //trim all the fields
         displayName = (displayName.trim());
         firstName = (firstName.trim());
         lastName = (lastName.trim());
         userName = (userName.trim());

         refreshValues();

         setShowErrorMessageUsername(false);
         setShowErrorMessageDisplayName(false);
         setShowErrorMessageFirstName(false);
         setShowErrorMessageLastName(false);
         setShowErrorGraph(false);
         setErrorMessage("");
    
        //check first name length
        if (firstName!=="") {
            if (firstName.length < MINIMUM_FIRST_NAME_LENGTH || firstName.length > MAXIMUM_FIRST_NAME_LENGTH) {
                setShowErrorMessageFirstName(true);
                setErrorMessage("First name should be between " + MINIMUM_FIRST_NAME_LENGTH + " and "+ MAXIMUM_FIRST_NAME_LENGTH +" characters!");
                return false;
            }
        }
        
        //check last name length
        if (lastName!=="") {
            if (lastName.length < MINIMUM_LAST_NAME_LENGTH || lastName.length > MAXIMUM_LAST_NAME_LENGTH) {
                setShowErrorMessageLastName(true);
                setErrorMessage("Last name should be between " + MINIMUM_LAST_NAME_LENGTH + " and "+ MAXIMUM_LAST_NAME_LENGTH +" characters!");
                return false;
            }
        }
       
        //check if username is provided
        if (userName === "") {
            setShowErrorMessageUsername(true);
            setErrorMessage("Please provide username.");
            return false;
        }

        //validate username
        if (!validateEmailUsername(userName)){
            setShowErrorMessageUsername(true);
            setErrorMessage("Username is not in valid format");
            return false;
        }

        //check if displayName is provided
        if (displayName === ""){
            setShowErrorMessageDisplayName(true);
            setErrorMessage("Please provide a display name.");
            return false;
        }

        //check displayName length
        if (displayName.length < MINIMUM_DISPLAY_NAME_LENGTH || displayName.length > MAXIMUM_DISPLAY_NAME_LENGTH) {
            setShowErrorMessageDisplayName(true);
            setErrorMessage("Display name should be between " + MINIMUM_DISPLAY_NAME_LENGTH + " and " +MAXIMUM_DISPLAY_NAME_LENGTH + " characters in length!");
            return false;
        }
        
        return true;

    }
    
    function createUser() {
        //here we should add the user, with the fields that he entered

        if (!validateFields()) return;

        var firstNameValue = firstName.trim();
        if (firstNameValue.length == 0) {
            firstNameValue = undefined;
        }

        var lastNameValue = lastName.trim();
        if (lastNameValue.length == 0) {
            lastNameValue = undefined;
        }

        var displayNameValue = displayName.trim();

        var userNameValue = userName.toLowerCase();

        var emailValue = userNameValue + '@' + state.integrationData.domain.toLowerCase();
        

        const createUserRequest = {
            accountEnabled: true,
            displayName: displayNameValue,
            mailNickname: userNameValue,
            onPremisesImmutableId: emailValue,
            userPrincipalName: emailValue,
            mail: emailValue,
            givenName: firstNameValue,
            surname: lastNameValue,
            passwordProfile : {
                forceChangePasswordNextSignIn: true,
                password: generate({ // Generate dummy password
                    length: 12,
                    numbers: true,
                    symbols: true,
                    lowercase: true,
                    uppercase: true,
                    strict: true
                })
            }
        };

        log(createUserRequest);

        graphApi.graphClientInstance()
            .api('/users')
            .responseType(ResponseType.RAW)
            .post(createUserRequest)
            .then((response) => {
                log(response);

                if (response) {
                    if (!response.ok) {
                        response.json().then(body => {
                            log("Graph API response body");
                            log(body);

                            var errorText = "";
                            try {
                                errorText = body.error.message;
                            } catch(e) {}

                            // Azure returns "Property netId is invalid." on duplicate username
                            if (errorText.includes("netId")) {
                                errorText = t('dialog.azure_user_already_exists');
                            } else {
                                errorText = t('dialog.azure_error_message') + ' ' + errorText;
                            }

                            setErrorMessage(errorText);
                            setShowErrorGraph(true);
                        });
                    } else {

                        response.json().then(body => {
                            log("Graph API response body");
                            log(body);
                            // let link = "https://admin.microsoft.com/Adminportal/Home#/users/:/UserDetails/" + body.id // this link not working properly first time 
                            let text = "https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/" + body.id
                            const link = <a href={text} target="_blank" > {t('create_azure_user.user_created2')} </a>
                            let component = <>{t('create_azure_user.user_created1')} {link} {t('create_azure_user.user_created3')} </>
                            //show green snackbar after successful creation of azure user with link of current user
                            dispatch({ type: ACTION_TYPE.SHOW_CREATE_AZURE_USER_SNACK, payload: { show: true, link: component } });
                            window.location.assign("#/integrations");
                        });
                       
                    }
                } else {
                    // This should not happen normally
                    setErrorMessage("");
                    setShowErrorGraph(true);
                }
            })
            .catch((error) => {
                log(error);

                // This should not happen normally
                setErrorMessage("");
                setShowErrorGraph(true);
            });
    }

    const handleFirstNameChange = (e) => {
        const newFirstName = e.target.value;
        whichTextField = ("first")

        if (defaultDisplayName){
            if(lastName !== "") {
                displayName = (newFirstName + " " + lastName);
            }
            else {
                displayName = (newFirstName);
            }
        }
       firstName = (newFirstName);
       refreshValues();
      }
    
   
    const handleLastNameChange = (e) => {
        const newLastName = e.target.value;
        whichTextField = ("last")

        if (defaultDisplayName){
            if(newLastName !== "") {
                displayName = (firstName + " " + newLastName);
            }
            else {
                displayName = (firstName);
            }
        }
        lastName = (newLastName);
        refreshValues();
    }
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ width: '1000px', marginBottom: '10px' }}>
            <Grid item xs alignItems="left">

                <div >
                    <Typography sx={{paddingLeft:'10px'}} variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', color:'black' , opacity: !state.showUserDetails?'0.2':'1'}}>
                        First name
                    </Typography>
                </div>
                <div>
                    <TextField style={{ 
                            textAlign: "left",
                            font: "normal normal normal 16px/24px Source Sans Pro",
                            letterSpacing: "0.15px",
                            color: "A2A0A0",
                            backgroundColor: "#F2F2F2",
                            border: "0",
                            width: '100%'
                            }}
                            autoFocus = {enteredWhichTextField=="first"}
                            variant ='outlined'
                            value = {firstName}
                            onChange={handleFirstNameChange}
                            disabled = {!state.showUserDetails}
                        />
                        {showErrorMessageFirstName &&
                            <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }
                </div>

                <div >
                    <Typography sx={{paddingLeft:'10px', paddingTop:'40px'}} variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', color:'black', opacity: !state.showUserDetails?'0.2':'1'}}>
                        Last name
                    </Typography>
                </div>
                <div>
                    <TextField style={{ 
                            textAlign: "left",
                            font: "normal normal normal 16px/24px Source Sans Pro",
                            letterSpacing: "0.15px",
                            color: "A2A0A0",
                            backgroundColor: "#F2F2F2",
                            border: "0",
                            width: '100%'
                            }}
                            autoFocus = {enteredWhichTextField=="last"}
                            variant ='outlined'
                            value = {lastName}
                            onChange={handleLastNameChange}
                            disabled = {!state.showUserDetails}
                        />
                        {showErrorMessageLastName &&
                            <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }
                </div>

                <div >
                    <Typography sx={{paddingLeft:'10px', paddingTop:'40px'}} variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', opacity: !state.showUserDetails?'0.2':'1'}}>
                        Display name <span style={{ verticalAlign: "super" }}>*</span>
                    </Typography>
                </div>
                <div>
                    <TextField style={{ 
                            textAlign: "left",
                            font: "normal normal normal 16px/24px Source Sans Pro",
                            letterSpacing: "0.15px",
                            color: "A2A0A0",
                            backgroundColor: "#F2F2F2",
                            border: "0",
                            width: '100%'
                            }}
                            autoFocus = {enteredWhichTextField=="display"}
                            variant ='outlined'
                            value = {displayName}
                            onChange={(e) => {
                                whichTextField = ("display");
                                if(e.target.value === "") defaultDisplayName = (true);
                                else defaultDisplayName = (false);
                                displayName = (e.target.value);
                                refreshValues();
                              }}
                            disabled = {!state.showUserDetails}
                        />
                        {showErrorMessageDisplayName &&
                            <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }
                </div>
                    
                <div >
                    <Typography sx={{paddingLeft:'10px', paddingTop:'40px'}} variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', opacity: !state.showUserDetails?'0.2':'1'}}>
                        Username <span style={{ verticalAlign: "super" }}>*</span>
                    </Typography>
                </div>
                <div style={{display:'flex'}}>
                       <Box sx={{width:'50%'}}>
                       <TextField sx={{width:'100%'}} style={{ 
                                textAlign: "left",
                                font: "normal normal normal 16px/24px Source Sans Pro",
                                letterSpacing: "0.15px",
                                color: "A2A0A0",
                                backgroundColor: "#F2F2F2",
                                border: "0",
                                }}
                                autoFocus = {enteredWhichTextField=="user"}
                                variant ='outlined'
                                value = {userName}
                                onChange={(e) => {
                                    whichTextField = ("user");
                                    userName = (e.target.value);
                                    refreshValues();
                                  }}
                                disabled = {!state.showUserDetails}
                            />
                       {showErrorMessageUsername &&
                            <div style={{ fontSize: '10px', marginTop: 10, width: "100%" }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                            }
                       </Box>
                        
                            
                        <Typography  sx={{fontSize:'20px',paddingLeft:'10px', width:'50%', alignItems:'center', display:'flex', justifyContent:'left', opacity: !state.showUserDetails?'0.2':'1'}}>@{state.integrationData.domain}</Typography>
                        
                   
                </div>

                <div style={{ marginTop: "82px", width: "110%", textAlign: "start" }}>
                        <Button  disabled = {!state.showUserDetails} sx={{width:'130px', height:'32px'}} onClick={createUser}>{t('create_azure_user.create_user')}</Button>
                        {showErrorGraph &&
                            <div style={{ fontSize: '10px', marginTop: 10, width: "100%" }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }        
                </div>
                
                 
            </Grid>
            
        </Box>
    )
}

function CreateAzureUser(props) {
    const { t } = useTranslation();
    const { state, dispatch } = React.useContext(context);
    const [showGrantAccessButton, setShowGrantAccessButton] = React.useState(true);
    const [adminEmail, setAdminEmail] = React.useState("");

    const [showErrorMessageDisplayName, setShowErrorMessageDisplayName] = React.useState(false);
    const [showErrorMessageUsername, setShowErrorMessageUsername] = React.useState(false);
    const [showErrorMessageLastName, setShowErrorMessageLastName] = React.useState(false);
    const [showErrorMessageFirstName, setShowErrorMessageFirstName] = React.useState(false);

    const [showErrorGraph, setShowErrorGraph] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState("");

    const { instance, accounts } = useMsal();

    log(instance);
    log(accounts);

    // Go back to list of integrations on refresh
    useEffect(() => {
        if (!state.integrationData.domain) {
            window.location.assign("#/integrations");
        }

        firstName = "";
        lastName = "";
        displayName = "";
        userName = "";

        whichTextField = "first";
        defaultDisplayName = true;
        dispatch({ type: ACTION_TYPE.SHOW_USER_DETAILS, payload: false });
        setShowGrantAccessButton(true);
    }, []);



    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ height: '700px', width: '1000px' }}>
            <Grid item xs alignItems="left">
                <div>
                    
                    <Typography variant="createAzureUserHeading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                        <div>
                            {t('create_azure_user.create_azure_user_heading')}
                            <span style={{ color: '#A2A2A2', fontWeight: 400, marginLeft: 5 }} >{integrationName}</span>
                        </div>
                    </Typography>

                </div>
                <div>
                    <Typography variant="createAzureUserSubheading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                        <div>
                            {t('create_azure_user.create_azure_user_subheading1')}
                        </div>
                    </Typography>
                </div>
                <div>
                    <Typography variant="createAzureUserSubheading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                        <div>
                            {t('create_azure_user.create_azure_user_subheading2', {domain: state.integrationData.domain})}
                        </div>
                    </Typography>
                </div>
                
                <Grid item xs style={{ maxWidth: '900px', marginTop: '100px' }}>
                    {currentServiceProvider.custom === null &&
                        <div>
                            <Section heading={t('create_azure_user.grant_access_heading')} content={
                                <GrantAccessButtonToggle showGrantAccessButton={showGrantAccessButton} setShowGrantAccessButton={setShowGrantAccessButton} adminEmail={adminEmail} setAdminEmail={setAdminEmail}/>
                            } />
                        </div>
                    }
                    <div style={{ marginTop: "82px", paddingBottom:'10%', width: "110%" }}>
                    <Section heading={ t('create_azure_user.add_user_details')} isErrorOccurred={errorMessage!=""} content={  <UserDetails 
                        showErrorMessageDisplayName = {showErrorMessageDisplayName} setShowErrorMessageDisplayName = {setShowErrorMessageDisplayName}
                        showErrorMessageUsername = {showErrorMessageUsername} setShowErrorMessageUsername = {setShowErrorMessageUsername}
                        errorMessage = {errorMessage} setErrorMessage = {setErrorMessage}
                        showErrorMessageLastName = {showErrorMessageLastName} setShowErrorMessageLastName = {setShowErrorMessageLastName}
                        showErrorMessageFirstName = {showErrorMessageFirstName} setShowErrorMessageFirstName = {setShowErrorMessageFirstName}
                        showErrorGraph = {showErrorGraph} setShowErrorGraph = {setShowErrorGraph}
                          /> } />
                    </div>
                </Grid>

            </Grid>
           
           </Box>
    )
}

function CreateAzureUserPage(props) {
    const [activeStep, setActiveStep] = React.useState(0);

    const steps = [<CreateAzureUser/>];

    const getCurrentStepCallback = (step) => {
        setActiveStep(step);
    };
    const { state, dispatch } = React.useContext(context);

    return (
        <BasicLayout sx={{backgroundColor: "#FFFFFF"}} content={
            <FullScreenStepper steps={steps} gotoStep={activeStep} currentStep={getCurrentStepCallback} isAddIntegration={'true'} />
        } hasFooter={false} />
    );
}

export default withRouter(CreateAzureUserPage);
