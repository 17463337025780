import React from "react";
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { useTranslation } from "react-i18next";
import { Button, InverseButton } from "./CustomUI";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {log} from '../helpers/utils'
import GraphApi from "../helpers/graph";
import { context } from "../State";

export default function DropDown(props) {
  const { items, currentDomain, setError, setIsPrimary, setPrimaryText } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [primaryDomain, setPrimaryDomain] = React.useState("");
  const { state } = React.useContext(context);

  const graphApi = new GraphApi();
  graphApi.init(state.graphApiAccessToken);


  const buttonClicked = (event) => {
    setOpenDialog(true);
    setAnchorEl(event.currentTarget);
  }


  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const errorSetter = (error) => {
    setError(error);
  }

  function setIsPrimaryCallback() {
    setIsPrimary(false);
    setError("");
    setPrimaryText(t("domain_federation.domain_is_not_primary"))
}


  let selectedDomain;

  async function selectPrimaryDomain() {
    log("Primary domain is " + primaryDomain);

    await graphApi.updateDefaultStatus(currentDomain, false, undefined, errorSetter).then(() => {
      graphApi.updateDefaultStatus(primaryDomain, true, setIsPrimaryCallback, errorSetter);
    });

    setOpenDialog(false)
  }

  function selectDomain(domain) {
    selectedDomain = domain;
    setPrimaryDomain(selectedDomain);
    log("Selected domain is " + selectedDomain);

  }


  return (

    <div>
      <Button style={{ backgroundColor: 'white', color: 'black', maxWidth: 230, padding: 2, marginBottom: 8 }} onClick={buttonClicked} uppercase={false}>{t('domain_federation.select_domain_to_primary')}<ExpandMoreIcon sx={{ width: 20, height: 25, color: 'black' }} /></Button>
      <Menu
        anchorEl={anchorEl}
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '8px 0px 8px 8px',
            opacity: 1,
            padding: 10
          },
          elevation: 0,

        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleDialogClose}

      >
        <div>
          <div style={{
            textAlign: "left",
            font: 'normal normal bold 1.1vw/1vh Source Sans Pro',
            letterSpacing: "0px",
            color: "#152035",
            opacity: 1,
            marginBottom: '10%',
            paddingLeft: '6%'
          }}>
          </div>

          {items.map((item, index) => {
            return (
              <div>
                <MenuItem onClick={e => selectDomain(item.id)}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography type="body2"
                        style={{
                          textAlign: 'left',
                          font: 'normal normal normal 1.1vw/1vh Source Sans Pro',
                          letterSpacing: '0px', color: '#152035', opacity: 1
                        }}
                      >{item.id}</Typography>} />
                </MenuItem>
                {index !== (items.length) && <Divider />}
                {index == (items.length - 1) && <InverseButton style={{ backgroundColor: 'black', color: 'white', marginBottom: 0, }} onClick={selectPrimaryDomain} >{t('domain_federation.change_domain_to_primary')}</InverseButton>}
              </div>
            );
          })
          }
        </div>
      </Menu>
    </div>);

}
