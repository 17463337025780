import * as React from "react"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function ThreeDotsIcon(props) {

    const localRef = React.useRef();

    React.useEffect(() => {
        // Hacking in the "click()" function that Stonly calls
        const svg = localRef.current;
        if (svg) {
            svg.click = (() => {
                const event = { currentTarget: svg, stopPropagation: () => {} };
                props.onClick(event);
            });
        }
    }, []);

    return (
        <MoreHorizIcon {...props} ref={localRef} />
    )

}

export { ThreeDotsIcon };
