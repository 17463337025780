import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ThreeDotsIcon } from '../img/3-dots-icon';
import {useImperativeHandle, forwardRef} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Box from "@mui/material/Box/Box";
import {Draggable, Droppable} from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

let today = new Date().getTime();
/**
 * Custom group that consist of a group header (Accordion component) and a group content (Table component).
 * Group header is expandable on a click. Group name, description, color and actions can be passed as props.
 * Group content is a table. Table headers and table rows can be passed as props (array).
 * Every row has an info icon on right side and when clicked, 'rowInfoClicked' callback is triggered.
 * If dragAndDrop prop is passed as true, rows can be moved inside the group or between groups. This requires a surrounding tag:
 * <DragDropContext onDragEnd={onDragEnd}>
 *     ...
 *     </Group>
 *     ...
 * </DragDropContext>
 * where 'onDragEnd' method needs to be implemented.
 * And every row needs to have an unique id in order for drag and drop to work.
 * @param props
 * @param ref
 * @returns {*}
 * @constructor
 */
const Group = (props, ref) => {
    const {groupName, groupDescription, groupActions, groupColor, tableHeaders, tableRows, rowInfoClicked, dragAndDrop, id} = props;

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        getSelectedRow() {
            return ""
        }
    }));

    const onTableRowClick = (index, event, rowId, tableId) => {
        rowInfoClicked(index, event, rowId, tableId)
    };

    return (
        <TableHeaderAccordion name={groupName}
                              description={groupDescription}
                              actionItems={groupActions}
                              color={groupColor}
                              content={<CustomTable tableHeaders={tableHeaders} tableRows={tableRows} dragAndDrop={dragAndDrop} onTableRowClick={onTableRowClick} id={id}/>}/>
    );
};

function TableHeaderAccordion(props) {
    const {name, description, actionItems, color, content} = props;
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = (event, expanded) => {
        if (expanded) {
            setExpanded(expanded)
        } else {  // a little delay because of the animation
            setTimeout(
                () => setExpanded(expanded),
                50
            );
        }
    };

    return (
        <div>
            <Accordion defaultExpanded={true} onChange={handleChange} sx={{boxShadow:'none'}}>
                <AccordionSummary
                    expandIcon={<ExpandLessIcon sx={{width: '5vw', height: '5vh', color: 'black'}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{alignItems:'center', borderRadius:'36px', backgroundColor: color, paddingRight: '2vw'}}
                >
                    <Typography variant="h3" sx={{ fontSize: '2vw', alignSelf: 'center', paddingLeft:'1%', fontWeight: 700}}>
                        {name}
                    </Typography>
                    <Typography variant="h3" sx={{marginLeft: '10px', marginRight: '10px', alignSelf: 'center'}}>
                        |
                    </Typography>
                    <Typography variant="h4" sx={{ fontSize:'1vw', alignSelf: 'center', fontWeight: 700}}>
                        {description.substring(0, description.indexOf(':'))}
                    </Typography>
                    <Typography variant="h4" sx={{ fontSize:'1vw', alignSelf: 'center' }}>
                        {description.substring(description.indexOf(':'))}
                    </Typography>
                    <Box sx={{marginRight:'1vw', marginLeft: 'auto', display: 'inline-flex', alignItems:'center'}}>
                        {actionItems}
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{display: expanded ? 'flex' : 'none'}}>
                    {content}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const DraggableComponent = (id, index) => (props) => {
    return (
        <Draggable draggableId={`${id}`} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    <TableCell align="left" {...provided.dragHandleProps}>
                        <DragIndicatorIcon />
                    </TableCell>
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
};

const DroppableComponent = (index) => (props) =>
{
    return (
            <Droppable droppableId={`${index}`} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>

    )
};

const CustomTable = (props, ref) => {

    const {tableHeaders, tableRows, onTableRowClick, dragAndDrop, id} = props;

    const handleClick = (index, event, rowId, tableId, rowExpirationDate) => {
        let expired = rowExpirationDate - today <= 0;
        if(!expired) {
        onTableRowClick(index, event, rowId, tableId)
        } else {
            return;
        }
    };

  
    var colCnt = 0;
    function firstColumn(){
        colCnt=(colCnt + 1);
        return colCnt<=1;
    }
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {dragAndDrop && <TableCell/>}
                        {tableHeaders.map((title, index) => (
                            <TableCell align="start"  sx={{width: firstColumn()? '5vw':'20vw'}} key={title}>
                                <Typography variant="h4" sx={{fontWeight: 700}}>
                                    {title}
                                </Typography>
                            </TableCell>

                        ))}
                        {/* Extra cell above the 3 dot icon */}
                        <TableCell align="start" sx={{ width: '5vw'}} >
                                
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody component={ dragAndDrop ? DroppableComponent(id) : ''}>
                    {tableRows.map((row, index) => (
                        <TableRow
                            component={dragAndDrop ? DraggableComponent(row.id, index) : ''}
                            key={`${index} ${row.id}`}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >

                            {Object.values(row).map((cellData, index) => (
                                (index !== 0 && index !== 11) ?
                                <TableCell align= {index > 4 ? "center" : "start"} 
                                           key={`${index} ${cellData}`} 
                                           style={{opacity: ((row.expirationDate - today <= 0) && (index !== 4) ) ? 0.3 : 1}}>
                                    {typeof cellData === 'string' && cellData.length > 30 && 
                                    <Typography variant="h4" >
                                        {cellData.substring(0,30)}...
                                    </Typography>}
                                    {(typeof cellData != 'string' || cellData.length <= 30) && 
                                    <Typography variant="h4">
                                        {cellData}
                                    </Typography>}
                                </TableCell> : ''
                            ))}
                            <TableCell align="start" key={row.id} style={{opacity: (row.expirationDate - today <= 0)? 0.3 : 1}} >
                                <ThreeDotsIcon sx={{width:'3.5vw', height: '3.5vh'}} onClick={(e) => {handleClick(index, e, row.id, id, row.expirationDate)}}/>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="center">
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default forwardRef(Group);

