import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useEffect} from "react";
import { Link } from 'react-router-dom';


export default function FullScreenStepper(props) {

    const { steps, gotoStep, currentStep, isAddIntegration } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    useEffect(
        () => {
            setStep(gotoStep);
        },
        [gotoStep]
    );

    useEffect(
        () => {
            currentStep(activeStep)
        },
        [activeStep]
    );

    const setStep = (step) => {
        if (step > 0 && step < steps.length) {
            setActiveStep(step);
        }
    };

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        { activeStep !== 0 &&
                            <IconButton
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                disableRipple
                                sx={{mr: 1, alignItems: 'start', marginRight: isAddIntegration? '100px' : '0px'}}
                            >
                                <KeyboardBackspaceIcon/>
                            </IconButton>
                        }
                        { (activeStep === 0 && isAddIntegration === 'true') && 
                            <IconButton
                                color="inherit"
                                component={Link}
                                to="/integrations"
                                disableRipple
                                sx={{paddingTop:'3vh', mr: 1, alignItems: 'start', marginRight: '5vw'}}
                            >
                                <KeyboardBackspaceIcon sx={{width:'4vw', height:' 4vh'}}/>
                            </IconButton>
                        }
                        {steps[activeStep]}
                        <Box sx={{ flex: '1 1 auto' }} />
                    </Box>
                </React.Fragment>
        </Box>
    );
}