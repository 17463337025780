import React, { useEffect, useState, useRef } from "react";
import { Button, InverseButton, ExitButton, TextField, Link, CustomCopyIcon } from './CustomUI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CustomSnackbar from "./Snackbar";
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/material";
import Api from '../helpers/service'
import { log, validateEmail, lowerAndTrimEmail } from '../helpers/utils'
import { context, ACTION_TYPE } from "../State";
import { CustomTooltip } from "./CustomUI";
import { getTheme } from "../theme/theme";
import { FormControl } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Grid } from "@mui/material";
import { FormControlLabel } from "@mui/material"
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import DateTimePickerCustom from "./DateTimePicker";
import i18n from "../i18n/config";

export default function CustomDialogTextBox(props) {
  const { open, onConfirm, onCancel, title, label, confirmBtnText, cancelBtnText, confirmBtnTextAdditional, id, authenticationSettingsDto } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();
  const [linkCopiedText, setLinkCopiedText] = React.useState('');
  const [linkCopiedTextCode, setLinkCopiedTextCode] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [magicLink, setMagicLink] = React.useState('');
  const [code, setCode] = React.useState('');
  const [resultReady, setResultReady] = React.useState(false);
  const [resultReadyCode, setResultReadyCode] = React.useState(false);
  const [errorTxt, setErrorTxt] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [showSnackErrorGrantOwnAccess, setShownShackErrorGrantOwnAccess] = React.useState(false);
  const [numberOfUsages, setNumberOfUsages] = React.useState(1);
  const [validFromTime, setValidFromTime] = React.useState(null);
  const [selectedNumberOfUsages, setSelectedNumberOfUsages] = React.useState(1);
  const [valueNumberOfUsages, setValueNumberOfUsages] = React.useState('one-time');
  const [valueValidFromTime, setValueValidFromTime] = React.useState('right-away');
  const api = new Api();
  const { state, dispatch } = React.useContext(context);
  const [isActive, setIsActive] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const blue = getTheme().palette.primary.main;
  const black = getTheme().palette.common.black;
  const lang = i18n.language;
  const numberOfUsagesOptions = [1,2,3,4,5,6,7,8,9,10]

  const CopyNotification = styled('div')({
    marginLeft: 20.5,
    font: 'normal normal normal 9px/13px Source Sans Pro',
    color: '#A2A0A0',
    width: "100%"
  });

  const handleClick = () => {
    setIsActive(true);
  };
  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    
    setOpenDialog(false);
    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_INVITE_NEW_USER, payload: false });
    setResultReady(false);
    setResultReadyCode(false);
    setLinkCopiedText('');
    setLinkCopiedTextCode('');
    setErrorTxt('');
    setShowCode(false);
    setValidFromTime(null);
    setNumberOfUsages(1);
    setSelectedNumberOfUsages(1);
    setValueNumberOfUsages('one-time');
    setValueValidFromTime('right-away');
  };

  const handleTextChange = (e) => {
    setEmail(e.target.value);
    setErrorTxt('');
  };

  const handleSnackBarErrorClose = () => {
    setShownShackErrorGrantOwnAccess(false);
  }

  const copyToClipboard = () => {
    log("Magic link: " + magicLink);
    setLinkCopiedText(t('dialog.link_copied'))
    try {
      navigator.clipboard.writeText(magicLink);
    } catch (e) {
      log("Error occurred. ", e);
    }
  };

  const copyToClipboardCode = () => {
    log("Code: " + code);
    setLinkCopiedTextCode(t('dialog.code_copied'))
    try {
      navigator.clipboard.writeText(code);
    } catch (e) {
      log("Error occurred. ", e);
    }
  };

  function createMagicLink(email) {
    if (!validateEmail(email)) {
      setErrorTxt(t('dialog.error_text'));
      return;
    }
    api.createMagicLink(email, state.integrationData.domain, state.integrationData.integrationId, lang).then(function (res) {
      log(res.data);
      if (!res.data.errorMessage) {
        setMagicLink(res.data.url);
        setEmail('');
        setResultReady(true);
      } else {
        setErrorTxt(res.data.url);
      }
    }).catch(function (error) {
      log(error);
      if (error.response.status == 403) {
        handleDialogClose();
        setErrorMessage(t('dialog.create_magic_link_error'));
        setShownShackErrorGrantOwnAccess(true);
      } else {
        setErrorTxt(t('dialog.not_same_domain'));
      }
    })

  }

  function createCode(email) {
    if (!validateEmail(email)) {
      setErrorTxt(t('dialog.error_text'));
      return;
    }


    api.createCode(email, state.integrationData.domain, state.integrationData.integrationId, 'en').then(function (res) {
      log(res.data);
      if (!res.data.errorMessage) {
        setCode(res.data.code);
        setResultReadyCode(true);
      } else {
        setErrorTxt(res.data.code);
      }
    }).catch(function (error) {
      log(error);
      if (error.response.status === 403) {
        handleDialogClose();
        setErrorMessage(t('dialog.create_magic_link_error'));
        setShownShackErrorGrantOwnAccess(true);
      } else {
        setErrorTxt(t('dialog.not_same_domain'));
      }
    })
  }

  function updateCode() {
    api.updateCode(email, state.integrationData.integrationId, numberOfUsages, validFromTime).then(function(res){
      log(res.data);
      setEmail('');
    }).catch(function (error) {
      log(error);
    })
  }

  const handleNumberOfUsagesChange = event => {
    setValueNumberOfUsages(event.target.value);
  }

  const handleChangeValidFromTime = event => {
    setValueValidFromTime(event.target.value);
  }

  const handleChangeSelection = event => {
    setSelectedNumberOfUsages(event.target.value);
    setNumberOfUsages(event.target.value);
  }


  return (
    <div>
      <Dialog
        id = {id}
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '8px',
            opacity: 1,
            width: 530
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'left' }}>
          <IconButton onClick={handleDialogClose} style={{ float: 'right' }}>
            <ExitButton />
          </IconButton>
          <br />
          <Typography component={'div'} variant="subtitle1" color="textPrimary" style={{ paddingLeft: '40px', margin: 'auto' }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "left", marginLeft: '30px' }}>
          <DialogContentText component={'div'} id="alert-dialog-description" style={{ marginTop: 12, width: "100%", overflow: 'hidden' }}>
            <TextField disabled={showCode} className={'txt-field'} label={label} variant="standard"
              onChange={e => handleTextChange(e)} error={errorTxt !== ''} helperText={errorTxt} style={{ width: 400, marginRight: 47 }} />
            {resultReady &&
              <span style={{display: "flex", marginTop: '30px'}} >
                <Link id="url" style={{ width: '50%' }}>
                  {magicLink}
                </Link>
                <span style={{ transform: 'translateY(25%)' }}>
                  <CustomTooltip title={t('tooltip.copy_link')} placement='top'>
                    <CustomCopyIcon
                      style={{ marginLeft: "30px", paddingLeft: "0px", cursor: 'pointer', color: isActive ? blue : black }}
                      onClick={() => { copyToClipboard(); handleClick() }}></CustomCopyIcon>
                  </CustomTooltip>
                  <CopyNotification>{linkCopiedText}</CopyNotification>
                </span>
              </span>
            }

            {resultReadyCode &&
              <div>
                <div>
                  <Grid
                    container
                    direction="row"
                    justifyContent="unset"
                    alignItems="flex-end"
                    sx={{marginTop: "25px"}}
                  >
                    <FormControl disabled={showCode}>
                      <RadioGroup
                        aria-labelledby="num-of-usages-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group-number-of-usages"
                        sx={{marginRight: "65px"}}
                        value={valueNumberOfUsages}
                        onChange={handleNumberOfUsagesChange}
                      >
                        <FormControlLabel value="one-time" control={<Radio />} label="One-time use" />
                        <FormControlLabel value="multiple" control={<Radio />} label="Multi-use" />
                      </RadioGroup>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ m: 1, width: "20%", height: "20px" }}>
                      <Select
                        disabled={valueNumberOfUsages === 'one-time' || showCode}
                        labelId="number-of-usages-select-standard-label"
                        id="number-of-usages-select-standard"
                        value={selectedNumberOfUsages}
                        onChange={handleChangeSelection}
                        label="Type"
                        style={{ height: "30px" }}
                      >
                        {numberOfUsagesOptions.map( number => <MenuItem value={number}> {number} </MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <FormControl disabled={showCode}>
                      <RadioGroup
                        aria-labelledby="valid-from-time-controlled-radio-buttons-group-valid-from-time"
                        name="controlled-radio-buttons-group"
                        value={valueValidFromTime}
                        onChange={handleChangeValidFromTime}
                      >
                        <FormControlLabel value="right-away" control={<Radio />} label="Use right away" />
                        <FormControlLabel value="specific-time" control={<Radio />} label="Use on specific time" />
                      </RadioGroup>
                    </FormControl>
                    <DateTimePickerCustom
                      sx={{marginBottom: '-8px'}}
                      disabled={valueValidFromTime === 'right-away'}
                      setValidFromTime={setValidFromTime} />
                  </Grid>
                </div>
                {resultReadyCode &&
                  <Button disabled={showCode} onClick={() => { updateCode(); setShowCode(true) }} style={{ marginTop: 15, marginLeft: 2 }}> {t('generate')} </Button>
                }
                <span style={{ display: "flex", marginTop: '-4px', visibility: showCode ? 'visible' : 'hidden'}}>
                  <div id="url" style={{ width: '15%' }}>
                    {code}
                  </div>
                  <span>
                    <CustomTooltip title={t('tooltip.copy_code')} placement='top'>
                      <CustomCopyIcon
                        style={{ marginLeft: "30px", paddingLeft: "0px", cursor: 'pointer', color: isActive ? blue : black }}
                        onClick={() => { copyToClipboardCode(); handleClick() }}></CustomCopyIcon>
                    </CustomTooltip>
                    <CopyNotification>{linkCopiedTextCode}</CopyNotification>
                  </span>
                </span>
              </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "left", margin: 'auto', marginRight: '70px' }}>
          {!resultReady && !resultReadyCode &&
            <div>
              <Button variant="contained" onClick={() => createCode(email)} style={{ marginRight: 50 }}>{confirmBtnTextAdditional}</Button>
              <Button variant="contained" onClick={() => createMagicLink(email)} style={{ marginRight: 65 }} disabled= {authenticationSettingsDto !== null ? ((authenticationSettingsDto.CLIENT_CERTIFICATE_SD_ENABLED || authenticationSettingsDto.CLIENT_CERTIFICATE_MD_ENABLED) && authenticationSettingsDto.CLIENT_CERTIFICATE_BYOD_ALLOWED) : false}>{confirmBtnText}</Button>
            </div>
          }

          {
            (resultReady || resultReadyCode) &&
            <Button disabled={(!(resultReadyCode && showCode) && !resultReady)} onClick={handleDialogClose} style={{ marginRight: 0 }}>{t('done')}</Button>
          }

        </DialogActions>
      </Dialog>

      <CustomSnackbar open={showSnackErrorGrantOwnAccess} onCancel={handleSnackBarErrorClose} type={'error'} vertical={'bottom'} horizontal={'right'} text={errorMessage} buttonText={t('notification.okay')} showIcon='true' />
    </div>
  );

}