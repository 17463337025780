import React, { useEffect, useState, useRef } from 'react';
import Box from "@mui/material/Box/Box";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { configManager } from '../App';
import Api from "../helpers/service";
import { log } from '../helpers/utils';
import { context, ACTION_TYPE } from "../State";
import { CustomTooltip } from './CustomUI';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CustomCopyIcon } from './CustomUI';
import { getTheme } from '../theme/theme';
import { t } from 'i18next';

export default function TextFieldCopy(props) {
    const { infoDescription, isOidc, isMicrosoft, credentials, credentialsSetter, isLoadedCredentials, setIsLoadedCredentials, width, isCopied, value, isEditable, type, onChange, isLoadedScimCredentials, setIsLoadedScimCredentials, scimCredentials, setScimCredentials, } = props;
    const ref = useRef(null);
    const api = new Api();
    const { state, dispatch } = React.useContext(context);

    const widthStyle = width === undefined ? '100%' : width;
    const [open, setOpen] = React.useState(false);
    const [click, setClick] = React.useState(false);

    const blue = getTheme().palette.primary.main;
    const black = getTheme().palette.common.black;
    const lightGray = getTheme().palette.onHover.inverse;

    const handleClick = () => {
        setClick(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };
    
    const handleTooltipOpen = () => {
        setOpen(true);
        setTimeout(function(){
            setOpen(false);
        }, 1000);
        
    };
    

    const StyledTextField = styled(TextField)({
        textAlign: "left",
        font: "normal normal normal 16px/24px Source Sans Pro",
        letterSpacing: "0.15px",
        color: "A2A0A0",
        backgroundColor: "#F2F2F2",
        border: "0"
    });
    const copy = (e) => {
        document.getElementById(infoDescription.type).select();
        try {
            navigator.clipboard.writeText(ref.current.value);
        } catch (e) { }
    }

    const fetch = async () => {
        if (!isLoadedCredentials) {
            try {
                const { data } = await api.generateAppCredentials("/app/generateAppCredentials");
                credentialsSetter(data);
                setIsLoadedCredentials(true);
            } catch (err) {
                log(err);
            } 
        }
    }

    const fetchSCIM = async () => {
        if (!isLoadedScimCredentials) {
            try {
                const { data } = await api.generateSCIMCredentials("/app/generateSCIMCredentials");
                setScimCredentials(data);
                setIsLoadedScimCredentials(true);
            } catch (err) {
                log(err);
            }
        }
    }

    const fetchTeamviewerCredentials = async () => {
        if (!isLoadedCredentials) {
            try {
                const {data} = await api.getTeamviewerEndpointSso("/app/generateClientId");
                credentialsSetter(data);
                setIsLoadedCredentials(true);
            } catch (err) {
                log(err);
            }
        }
    }

    useEffect(() => {
        if (isOidc && infoDescription.type === "clientid") {
            fetch();
        } else
            if (infoDescription.type === "scimendpointurl" || infoDescription.type === "scimsecrettoken") {
                fetchSCIM();
            } else
                if (infoDescription.type === "tvendpointsso" || infoDescription.type === "tvendpointslo") {
                    fetchTeamviewerCredentials();
                }

    }, []);

    

    const getTextFieldValue = () => {
        switch (infoDescription.type) {
            case "endpointsso":
                return configManager.shibSsoRedirectEndpoint;
            case "endpointssopost":
                return configManager.shibSsoPostEndpoint;
            case "endpointslo":
                return configManager.shibSloRedirectEndpoint;
            case "entityid":
                return configManager.entityID;
            case "metadataurl":
                return configManager.shibIdpUrlMetadata;
            case "metadataurl_wsfed": 
                return configManager.wsfedIdpUrlMetadata;
            case "fingerprint":
                return configManager.certificateFingerprint;
            case "clientid":
                return credentials !== null ? credentials.clientId : "";
            case "clientsecret":
                return credentials !== null ? credentials.clientSecret : "";
            case "endpointauthorization":
                return configManager.endpointauthorization;
            case "endpointtoken":
                return configManager.endpointtoken;
            case "endpointjwks":
                return configManager.endpointjwks;
            case "configurationopenid":
                return configManager.configurationopenid;
            case "scimsecrettoken":
                // If integration doesn't have scim enabled or it is new Integration we show generated credentials from /generateScimCredentials path in Edit integration/addIntegraton page, if scim is enabled for integration we show in edit/view credentials from database.
                return (state.integrationData.scimEnabled !== true) ? (scimCredentials !== null ? scimCredentials.scimSecretToken : "" ) : state.integrationData.scimSecret;
            case "scimendpointurl":
                return (state.integrationData.scimEnabled !== true) ? (scimCredentials !== null ? "https://" + configManager.serverName + "/scim/v2/" + scimCredentials.scimClientId : "") : state.integrationData.scimEndpointUrl ;
            case "tvendpointsso": 
                return credentials != null ? credentials.endpointSSo : "";
            case "tvendpointslo":
                return credentials != null ? credentials.endpointSlo : "";
        }
    }
    const iconAdornment = (isCopied!==false)
        ? {
            endAdornment: (<InputAdornment
                position="end">
                <ClickAwayListener onClickAway = {handleTooltipClose}>
                    <CustomTooltip 
                            title = 'Copied' 
                            placement = 'top' 
                            PopperProps={{disablePortal: true}}
                            onClose={handleTooltipClose}
                            open={open}
                            leaveDelay = '1000'
                            disableInteractive = 'true'
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener>
                        <CustomCopyIcon sx={{maxHeight:'23px', maxWidth: '23px',minHeight:'23px', minWidth: '23px',color: click ? blue : black, '&:hover': {color :click ? blue: lightGray}}}
                                         onClick={(e) => {copy(e); handleTooltipOpen(); handleClick();}} />
                    </CustomTooltip>
                </ClickAwayListener>
            </InputAdornment>)
        }
        : {};

    return (
        <Box style={{ marginBottom: '32px' }}>
            <div>
                <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block', color:'black'}}>
                    {infoDescription.label}
                </Typography>
            </div>
            <div>
                <StyledTextField
                    variant="outlined"
                    disabled={isEditable !== true}
                    inputRef={ref}
                    id={infoDescription.type}
                    InputProps={iconAdornment}
                    defaultValue={value === undefined ? getTextFieldValue(): value}
                    onChange={(e)=> {
                        onChange(type,e);
                    }}
                    style={{ width: widthStyle, font: "normal normal normal 16px/24px Source Sans Pro" }} />
            </div>
            {(infoDescription.type === "scimsecrettoken") && t("integration_view.scim_secret_note")}
        </Box>
    );
}