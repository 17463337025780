import React from 'react';
import Box from "@mui/material/Box/Box";
import iosBadge from '../img/apple_store_badge.svg';
import androidBadge from '../img/google_play_store_badge.svg';
import {styled} from "@mui/material";

const Badge = styled('img')({
    width: 'auto',
    height: '4vh',
    verticalAlign: 'middle',
    paddingLeft: '20px'
});

export default function Footer(props) {
    return (
        <Box sx={{height: '1vh'}}>
            <Box sx={{display: '-webkit-inline-flex', position: 'absolute', right: '3vw',
                 '@media (min-width:1366px) and (max-width:1919px)': {
                    bottom: '2vw'
                },
                '@media (min-width:1920px)': {
                    bottom: '2vw',
                },
             paddingRight: '1vw'}}>
                <div>
                    <a href={props.iosLink} target="_blank" rel="noopener noreferrer">
                        <Badge id="ios-img" src={iosBadge} alt="App Store"/>
                    </a>
                </div>
                <div>
                    <a href={props.androidLink} target="_blank" rel="noopener noreferrer">
                        <Badge id="android-img" src={androidBadge} alt="Google Play"/>
                    </a>
                </div>
            </Box>
        </Box>
    );
}