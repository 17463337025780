import React from "react";
import { ClickAwayListener, Typography } from "@mui/material";
import { context, ACTION_TYPE } from "../State";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import { Button } from "./CustomUI";

const SettingsButton = styled(Button)(({ theme }) => ({
  background: '#2A3DC7 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: '10vw',
  height: '5vh',
  width: '8vw',
  font: 'normal normal 600 0.8vw/1vw Source Sans Pro',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: 1
}));

export default function ToolTipComponent(props) {
  const { t } = useTranslation();
  const { milliseconds, isIntegrationActive, licenseType } = props;
  const { dispatch } = React.useContext(context);
  const [openTooltip, setOpenTooltip] = React.useState(false);

  function showUpgradePlanDialog() {
    handleTooltipClose();
    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_UPGRADE_PLAN, payload: true });
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 750,
      font: 'normal normal normal 14px/20px Source Sans Pro',
      border: '1px solid #dadde9',
      width: 400,
      paddingLeft: 20,
      paddingBottom: 20
    },
  }));

  function handleTooltipOpen() {
    setOpenTooltip(true);
  }

  function handleTooltipClose() {
    setOpenTooltip(false);
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
    <Typography component={'div'} variant="subtitle1" color="textPrimary">
      <HtmlTooltip
        open={openTooltip}
        placement="bottom-start"
        title={
          <React.Fragment >
            <div style={{ textAlign: 'center' }}>
              <Typography
                component={'div'}
                color="textPrimary"
                variant="h4"
                style={{ marginTop: 20 }}>
                {milliseconds > 0 ? (licenseType === "Licensed" ? t('tooltip.talk_to_sales_heading_not_expired_contract'): t('tooltip.talk_to_sales_heading_not_expired_trial') ):(licenseType === "Licensed" ? t('tooltip.talk_to_sales_heading_expired_contract'): t('tooltip.talk_to_sales_heading_expired_trial'))}
              </Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
              <SettingsButton style={{ marginBottom: '10px', marginTop: '11px' }} onClick={showUpgradePlanDialog}>
                {t('tooltip.talk_to_sales_button_heading')}
              </SettingsButton>
            </div>
          </React.Fragment>
        }
      >
        <InfoIcon style={{ height: '16px', width: '25px', verticalAlign: 'super', fontSize: 'medium', color: !isIntegrationActive ? "#293DC7" : "black" }} onClick={handleTooltipOpen} />
      </HtmlTooltip>
    </Typography>
    </ClickAwayListener>

  );
}