import React, { useEffect, useState, useRef } from "react";
import {Button, InverseButton, ExitButton, TextField, Link, CustomCopyIcon} from './CustomUI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {styled} from "@mui/material";
import Api from '../helpers/service'
import {log, validateEmail, lowerAndTrimEmail} from '../helpers/utils'
import {context, ACTION_TYPE} from "../State";
import MuiDoneIcon from '@mui/icons-material/Done';
import MuiClearIcon from '@mui/icons-material/Clear';
import CustomSnackbar  from "./Snackbar";

export default function CustomDialogTextBoxUsers (props) {
  const {open, onConfirm, onCancel, title, label, confirmBtnText, cancelBtnText} = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const {t} = useTranslation();
  const [email, setEmail] = React.useState('');
  const [errorTxt, setErrorTxt] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [adminFound, setAdminFound] = React.useState(false);
  const api = new Api();
  const {dispatch} = React.useContext(context);
  const [showSnack, setShowSnack] = React.useState(false);
  const [showSnackErrorGrantOwnAccess, setShownShackErrorGrantOwnAccess] = React.useState(false);

  const DoneIcon = styled(MuiDoneIcon)({
    transform: 'translateY(25%)',
    color: 'green',
    height: 15,
    minWidth: 24
  });

  const ClearIcon = styled(MuiClearIcon)({
    transform: 'translateY(25%)',
    color: 'red',
    height: 15,
    minWidth: 24
  })
 
  
  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      log("Search email here: " + email);
      let emailEdited = lowerAndTrimEmail(email);
      if (!validateEmail(emailEdited)) {
        setAdminFound(false);
      } else {
        api.checkIfAdminExists(emailEdited).then(function (res) {
          setAdminFound(true);
        }).catch(function (error) {
          setAdminFound(false);
        })
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn);
  }, [email])

  const handleDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
    setEmail('');
    props.onConfirm();
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_ADD_ADMIN, payload:false});
    setOpenDialog(false);
    setAdminFound(false);
    setErrorTxt('');
  };

  const handleTextChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSnackBarClose = () => {
    setShowSnack(false);
  }

  const handleSnackBarErrorClose = () => {
    setShownShackErrorGrantOwnAccess(false);
  }

  function handleAddAdmin(email, event, reason) {
    log("Email: " + email);
    let emailEdited = lowerAndTrimEmail(email);
    api.grantAdminAccess(emailEdited).then(function (res) {
        log(res.data);
        setShowSnack(true);
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_ADD_ADMIN, payload:false});
        setAdminFound(false);
        setEmail('');
        props.onConfirm();
      }).catch(function (error) {
        log(error);
        setErrorMessage(error.response.data);
        setShownShackErrorGrantOwnAccess(true);
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_ADD_ADMIN, payload:false});
        setAdminFound(false);
        setEmail('');
      })

      setAdminFound(false);
      setEmail('');
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{style : {
          background: '#FFFFFF 0% 0% no-repeat padding-box', 
          boxShadow: '0px 3px 6px #00000029',
          borderRadius: '8px',
          opacity: 1
        },
      }}
      >
        <DialogTitle id="alert-dialog-title" style={{textAlign: 'left'}}>
          <IconButton onClick={handleDialogClose} style={{float: 'right'}}>
            <ExitButton/>
          </IconButton>
          <br/>
          <Typography component={'div'} variant="subtitle1" color="textPrimary" style={{paddingLeft: '62px', margin:'auto'}}>
                {title}
          </Typography>
        </DialogTitle>
        <DialogContent style={{textAlign: "left", marginLeft: '63px'}}>
          <DialogContentText component={'div'} id="alert-dialog-description" style={{marginTop: 12, width: "100%", overflow: 'visible'}}>
            <TextField className={'txt-field'} label={ label } variant="standard"
                           onChange={e => handleTextChange(e)} error={errorTxt !== ''} helperText={errorTxt} style={{width: 250, marginRight: 60}}/>
            { email !== '' && adminFound && 
              <div style={{fontSize: '10px', marginLeft: -5}}>
                <DoneIcon/>
                <span style={{color: 'green'}}>{t('admin_access.admin_found')}</span>
              </div>
            }
            { email !== '' && !adminFound && 
              <div style={{fontSize: '10px', marginLeft: -5}}>
                <ClearIcon/>
                <span style={{color: 'red'}}>{t('admin_access.admin_not_found')}</span>
              </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: "left", margin: 'auto', marginLeft: '75px', marginTop: '-15px'}}>
            <div>
              <Button variant="contained" style={{marginRight: 20}} disabled={adminFound === false} onClick={(event, reason) => handleAddAdmin(email,event, reason)}>{confirmBtnText}</Button>
              <InverseButton onClick={handleDialogClose} style={{marginRight: 47}}>
                  {cancelBtnText}
              </InverseButton>
            </div>
        </DialogActions>
      </Dialog>

      <CustomSnackbar open={showSnack} onCancel={handleSnackBarClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.share_integration_group_invitation')} buttonText={t('notification.okay')} showIcon = 'true'/>
      <CustomSnackbar open={showSnackErrorGrantOwnAccess} onCancel={handleSnackBarErrorClose} type={'error'} vertical={'bottom'} horizontal={'right'} text={errorMessage} buttonText={t('notification.okay')} showIcon = 'true'/>   
    </div>
  );
}