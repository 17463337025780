import React, { useEffect, useState, useRef } from "react";
import {Button, ExitButton} from './CustomUI';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Typography } from "@mui/material";
import {context, ACTION_TYPE} from "../State";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {styled} from "@mui/material";
import MuiLink from "@mui/material/Link/Link";
import {useTranslation} from "react-i18next";
import CustomDialog from "./DialogText";
import Api from "../helpers/service";
import {log, formatDate} from '../helpers/utils'
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import MuiDoneIcon from '@mui/icons-material/Done';
import inviteUserIcon from "../img/icon-invite-user.svg";
import CustomDialogTextBoxUsers from "./DialogTextBoxUsers";
import CustomSnackbar  from "./Snackbar";
import { CustomTooltip } from "./CustomUI";



export default function DialogTable (props) {
  const {t} = useTranslation();
  const {open, onCancel, title, id} = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const {state, dispatch} = React.useContext(context);
  const [isShown, setIsShown] = React.useState(null);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [admins, setAdmins] = React.useState([]);
  const api = new Api();
  
  const textAlignClass = "left";
  
  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const fetch = async () => {
    try {
      const {data} = await api.getAdmins("/ideesaml/get-admins");
      setAdmins(data);
    }catch (err) {
      log(err);
    }
  }
  useEffect(() => {
    fetch();
  }, []);

  const TableCell = styled(MuiTableCell)({
    textAlign: 'left',
    font: 'normal normal normal 14px/18px Source Sans Pro',
    letterSpacing: '0px',
    color: '#152035',
    opacity: 1
  });

  const Link = styled(MuiLink)({
    textAlign: 'center',
    font: 'normal normal bold 12px/17px Source Sans Pro',
    letterSpacing: '0px',
    color: '#293DC7',
    opacity: 1,
    textDecoration: 'none'
  });

  const DoneIcon = styled(MuiDoneIcon)({
    transform: 'translateY(25%)', 
    marginRight: 10, 
    color: 'blue'
  });

  const AddAdminIconButton = styled(IconButton)({
    "&:hover": {
      backgroundColor: "white"
    },
    "&:active": {
      backgroundColor: "white"
    }
  })

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 750,
      font: 'normal normal normal 14px/20px Source Sans Pro',
      border: '1px solid #dadde9',
      width: 400,
      paddingLeft: 20,
      paddingBottom: 20
    },
}));


  function handleDialogClose (event)  {
    setOpenDialog(false);
    setIsShown(null);
    onCancel(false);
  };


  function handleClose () {
    setIsShown(null);
  };

  //tooltip list items initialize
  const listItems = [
    t('tooltip.line1'), t('tooltip.line2'), t('tooltip.line3'), t('tooltip.line4'), t('tooltip.line5')
  ];



  function handleClickRevoke (index, event) {
    event.preventDefault();
    setIsShown({ [index]: event.currentTarget});
  }

  function handleRevokeAdminAccess (index, email) {
    api.revokeAdminAccess(email).then(function (res) {
      log(res.data);
      handleClose(index);
      dispatch({type: ACTION_TYPE.SHOW_ACCESS_REVOKED_SNACK, payload:true});
      fetch();
    }).catch(function (error) {
      log(error);
      handleClose(index);
    })
  }

  function handleOpenDialogAddAdmin (event) {
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_ADD_ADMIN, payload:true});
  }

  function handleTooltipOpen() {
    setOpenTooltip(true);
  }

  function handleTooltipClose() {
    setOpenTooltip(false);
  }

  const handleSnackAccessRevokedClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    dispatch({type: ACTION_TYPE.SHOW_ACCESS_REVOKED_SNACK, payload:false});
}

  return (
    <Dialog
      id = {id}
      open={openDialog}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{style : {
        background: '#FFFFFF 0% 0% no-repeat padding-box', 
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
        opacity: 1,
        maxWidth: '1000px',
        width: '792px'
      },}}
    >
      <DialogTitle id="alert-dialog-title" style={{marginLeft: '62px'}}>
        <IconButton onClick={(event) => handleDialogClose(event)} style={{float: 'right'}}>
          <ExitButton/>
        </IconButton>
        <br/>
        <Typography component={'div'} variant="subtitle1" color="textPrimary">
            {title}
            <HtmlTooltip
              open={openTooltip}
              placement="bottom-start"
              title={
                <React.Fragment >
                  <div>
                    <IconButton style={{float: 'right', transform: 'translateY(-50%)'}} onClick={ () => {
                      handleTooltipClose();
                    }}>
                      <ExitButton/>
                    </IconButton>
                    <Typography 
                      component={'div'} 
                      color="textPrimary" 
                      variant="h4_bold" 
                      style={{marginTop: 20}}>
                      {t('tooltip.heading')}
                    </Typography>
                  </div>
                  {listItems.map((item, index) => (
                    <div>
                      <DoneIcon style={{maxHeight:'24px', maxWidth: '24px',minHeight:'24px', minWidth: '24px'}}/>
                      {item}
                    </div>
                  ))}
                </React.Fragment>
              }
            >
          <InfoIcon style={{height: '16px', width: '25px'}} onMouseOver={handleTooltipOpen}/>
        </HtmlTooltip>
        </Typography>
        

      </DialogTitle>

      <DialogContent style={{textAlign: textAlignClass, width: "fit-content", marginLeft: '62px'}}>  
        <DialogContentText id="alert-dialog-description" style={{marginTop: 12, marginBottom: 40}}>

          <TableContainer style={{width: "660px", height: '250px'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{paddingLeft: 0, fontWeight: 'bold'}}>
                    {t('admin_access.email_address')}
                  </TableCell>
                  <TableCell style={{paddingLeft: 0, fontWeight: 'bold'}}>
                    {t('admin_access.status')}
                  </TableCell>
                  <TableCell style={{paddingLeft: 0, fontWeight: 'bold'}}>
                    {t('admin_access.last_edited')}
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <CustomTooltip title = 'Add Admin' placement = 'top'>
                      <AddAdminIconButton>
                          <img  style={{width: 23, height: 18}} src={inviteUserIcon} onClick={(event) => handleOpenDialogAddAdmin(event)} />
                      </AddAdminIconButton>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins !== undefined && admins.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                 <TableCell style={{paddingLeft: 0}}>{row.emailAddress}</TableCell>
                 <TableCell style={{paddingLeft: 0}}>{row.status}</TableCell>
                 <TableCell style={{paddingLeft: 0}}>
                   {formatDate(row.lastEdited)}
                 </TableCell>
                 <TableCell style={{paddingLeft: 0}}>
                    <Link style={{paddingLeft: 0}} href="#" onClick = {(e) => handleClickRevoke(index, e)}>
                          {t('admin_access.revoke_access')}
                    </Link>
                 </TableCell>
                 
                 <CustomDialog 
                      open={Boolean(isShown && isShown[index])} 
                      onConfirm={() => handleRevokeAdminAccess(index, row.emailAddress)}
                      onCancel={handleClose} 
                      title={t("admin_access.title_dialog")} 
                      subtitle={t("admin_access.subtitle_dialog")} 
                      email={row.emailAddress}  
                      confirmBtnText={t('admin_access.button_confirm_dialog')} 
                      cancelBtnText={t('dialog.button_cancel')} 
                      isCentered={true}
                 /> 

                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomDialogTextBoxUsers 
                    open={state.isOpenedDialogAddAdmin}
                    onConfirm={fetch}
                    title={t('admin_access.add_admin_heading')} 
                    label={t('admin_access.enter_admin_email')} 
                    confirmBtnText={t('admin_access.share')} 
                    cancelBtnText={t('dialog.button_cancel')}/> 
          <CustomSnackbar open={state.showAccessRevokedSnack} onCancel={handleSnackAccessRevokedClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.info_access_revoked')} buttonText={t('notification.okay')} showIcon = 'true'/>
                    
        </DialogContentText>
      </DialogContent>
  </Dialog>
  
  );
}