import React from 'react';
import MuiCard from "@mui/material/Card";
import MuiCardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";
import Typography from '@mui/material/Typography';
import {styled} from "@mui/material";

const Card = styled(MuiCard)({
    borderRadius: '1.5vw',
    '@media (max-width:1365px)': {
        minWidth: '450px',
        minHeight: '550px',
    }, 
    
    width: '23vw',
    height: '56vh',
   

    margin: 'auto',
    textAlign: 'center',
    
});

const Card2 = styled(MuiCard)({
    borderRadius: '1.5vw',
    margin: 'auto',
    textAlign: 'center',
    height: '100%',
    '@media (min-width:2560px)': {
        height:'80vh'
    },
   
});

const CardHeader = styled(MuiCardHeader)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}));


export default function ColouredHeaderCard(props) {
    const {title, title2, content, isEmpty} = props;
    const isRegistration = props.isRegistration;
    if(isRegistration){
        return (<Card2 elevation={8} sx={{
            display: 'table',
            margin: 'auto', 
            float: 'none'
        }}>
            <CardHeader
                title={
                    <div>
                        <Typography variant="h3" sx={{fontSize: 25, 
                            '@media (min-width:1366px) and (max-width:1919px)': {
                                fontSize:'1.2vw'
                            },
                            '@media (min-width:1920px)': {
                                fontSize:'1.5vw'
                            },
                            fontWeight:700, paddingTop:'5%', paddingLeft: '5%', paddingRight: '5%'}}>
                            {title}
                        </Typography>
                        <Typography variant="h3" sx={{fontSize: 25,
                            '@media (min-width:1366px) and (max-width:1919px)': {
                                fontSize:'1.2vw'
                            },
                            '@media (min-width:1920px)': {
                                fontSize:'1.5vw'
                            },
                            fontWeight:700, paddingBottom:'5%'}}>
                            {title2}
                        </Typography>
                    </div>
   
                }/>
            <CardContent style={{height: isEmpty ? "100%" : "", paddingBottom: 16, paddingLeft: 0, paddingRight: 0}}>
                {content}
            </CardContent>
        </Card2>)
    }
    else {
        return (<Card elevation={8}>
            <CardHeader
                title={
                    <div>
                        <Typography variant="h3" sx={{fontSize: 25,
                        '@media (min-width:2560px)': {
                            fontSize:40
                        },

                            fontWeight:700, paddingTop:'5%', paddingLeft: '5%', paddingRight: '5%'}}>
                            {title}
                        </Typography>
                    </div>
                    
                }/>
            <CardContent style={{ paddingBottom: 16, paddingLeft: 0, paddingRight: 0, 
            }}>
                {content}
            </CardContent>
        </Card>)
    }
    
}