import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {context, ACTION_TYPE} from "../../State";
import {Button} from '../../components/CustomUI';
import {stepClasses, styled} from "@mui/material";
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import upgradePlanIcon from '../../img/icon-upgrade.svg';
import deleteAccountIcon from '../../img/icon-delete.svg';
import downloadReportIcon from '../../img/icon-download-report.svg';

import {log} from "../../helpers/utils";

import Api from "../../helpers/service";

export default function SettingsPage(props) {
    const {text} = props;
    const { t} = useTranslation();
    const {dispatch} = React.useContext(context);
    const api = new Api();
    const SettingsButton = styled(Button)(({theme}) => ({
        background: '#2A3DC7 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '10vw',
        height: '5vh',
        width: '8vw',
        font: 'normal normal 600 0.8vw/1vw Source Sans Pro',
        letterSpacing: '0px',
        color: '#FFFFFF',
        opacity: 1
    }));

    function showUpgradePlanDialog() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_UPGRADE_PLAN, payload:true});
    }

    function showDownloadReportsDialog() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DOWNLOAD_REPORT, payload:true});
    }

    function showDeleteAccountDialog() {

        api.deleteAccountCheck().then(function(res) {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:true});
        }).catch(function (error) {
            log(error.response.data.error);
            if (error.response.data.error === "HAS_SHARED_INTEGRATIONS") {
                dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE, payload:true});
            } else {
                dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE, payload:true});
            }
        })
    }

    let items = [
        {text: t('settings.upgrade_plan'), imageUrl: upgradePlanIcon, backgroundColor: "#293DC7", textColor: "white", func: showUpgradePlanDialog},
        {text: t('settings.reports'), imageUrl: downloadReportIcon, backgroundColor: "white", textColor: "black", func: showDownloadReportsDialog},
        {text: t('settings.delete_account'), imageUrl: deleteAccountIcon, backgroundColor: "white", textColor: "black", func: showDeleteAccountDialog},
    ]

    return (
        <Box sx={{ width: '100%'}}>
            <Typography style={{marginLeft:'3vw', paddingLeft:'5vw'}} variant="h1" component="div" gutterBottom>
                Settings
            </Typography>
            <TableContainer style={{marginLeft:'5vw'}} component={Paper} sx={{
                boxShadow: 'none',
                width: '50%',
                overflowX: 'hidden'
            }}>
                <Table sx={{ marginTop: '3vh', paddingLeft: '2vw' }} aria-label="simple table">
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    borderBottom: '0.5px solid #707070'
                                }}
                            >
                                <TableCell component="th" scope="row" sx={{
                                    width: '4vw',
                                    textAlign: 'center'
                                }}>
                                    { item.imageUrl !== undefined &&
                                                <img src={item.imageUrl} style={{
                                                    backgroundColor: item.backgroundColor,
                                                    borderRadius: index === 0 ? '50%': '0',
                                                    width: index === 0 ? '3vw' : '2vw', 
                                                    height: index === 0 ? '5vh' : '3vh'
                                                }}/>

                                    }
                                </TableCell>
                                <TableCell>
                                    {index === 0 && 
                                         <SettingsButton onClick={item.func} style={{marginBottom: '10px'}}>
                                            {item.text}
                                        </SettingsButton>
                                    }

                                    {index !== 0 &&
                                        <Typography variant="button2" 
                                            component="div" 
                                            gutterBottom 
                                            style={{marginLeft: '1vw', cursor: 'pointer', width: 'fit-content'}} 
                                            onClick={item.func}>
                                            {item.text}
                                        </Typography>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}