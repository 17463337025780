
export default function ConfigManager() {

    let instance;
    

    function init() {

        let appName = document.querySelector('meta[name="app_name"]').content;
        let iosLink = document.querySelector('meta[name="ios_link"]').content;
        let androidLink = document.querySelector('meta[name="android_link"]').content;
        let shibSsoRedirectEndpoint = document.querySelector('meta[name="shib_sso_redirect_endpoint"]').content;
        let shibSsoPostEndpoint = document.querySelector('meta[name="shib_sso_post_endpoint"]').content;
        let shibSloRedirectEndpoint = document.querySelector('meta[name="shib_slo_redirect_endpoint"]').content;
        let entityID = document.querySelector('meta[name="idp_entity_id"]').content;
        let idpCert = document.querySelector('meta[name="idp_cert"]').content;
        let shibIdpUrlMetadata = document.querySelector('meta[name="shib_idp_url_metadata"]').content;
        let wsfedIdpUrlMetadata = document.querySelector('meta[name="wsfed_idp_url_metadata"]').content;
        let certificateFingerprint = document.querySelector('meta[name="certificate_fingerprint"]').content;
        let endpointauthorization = document.querySelector('meta[name="endpoint_authorization"]').content;
        let endpointtoken = document.querySelector('meta[name="endpoint_token"]').content;
        let endpointjwks = document.querySelector('meta[name="endpoint_jwks"]').content;
        let configurationopenid = document.querySelector('meta[name="configuration_openid"]').content;
        let serverName = document.querySelector('meta[name="server_name"]').content;
        let azureAppClientId = document.querySelector('meta[name="azure_app_client_id"]').content;
        let idpEntityIdWsfed = document.querySelector('meta[name="idp_entity_id_wsfed"]').content;
        let idpSigning1 = document.querySelector('meta[name="idp_signing_1"]').content;
        let idpSigning2 = document.querySelector('meta[name="idp_signing_2"]').content;
        let idpEndpoint = document.querySelector('meta[name="idp_endpoint"]').content;
        

        if (process.env.REACT_APP_ENV === 'DEBUG' || process.env.NODE_ENV === 'development') {
            appName = "AuthN";
            iosLink = "https://beta.itunes.apple.com/v1/app/1537125732";
            androidLink = "https://play.google.com/store/apps/details?id=com.getidee.authn.alpha";
            shibSsoRedirectEndpoint = "https://alpha-lb.saml.getidee.de/idp/profile/SAML2/Redirect/SSO";
            shibSloRedirectEndpoint = "https://alpha-lb.saml.getidee.de/idp/profile/SAML2/Redirect/SLO";
            shibSsoPostEndpoint = "https://alpha-lb.saml.getidee.de/idp/profile/SAML2/POST/SSO";
            entityID = "AuthN";
            idpCert = "/etc/idee/idp-signing.crt";
            shibIdpUrlMetadata = "https://alpha-lb.saml.getidee.de/idp/metadata";
            certificateFingerprint = "56:FC:5D:B7:90:91:84:62:A0:FF:36:7B:52:6A:45:1D:73:7F:D5:2C:71:F6:E2:AC:14:CD:7A:D8:19:9A:A5:70";
            endpointauthorization = "https://alpha.getidee.de/oidc/authorization";
            endpointtoken = "https://alpha.getidee.de/oidc/token";
            endpointjwks = "https://alpha.getidee.de/oidc/jwks.json";
            configurationopenid = "https://alpha.getidee.de/.well-known/openid-configuration";
        }

        return {
            // Public methods and variables
            appName: appName,
            iosLink: iosLink,
            androidLink: androidLink,
            shibSsoRedirectEndpoint: shibSsoRedirectEndpoint,
            shibSloRedirectEndpoint: shibSloRedirectEndpoint,
            shibSsoPostEndpoint: shibSsoPostEndpoint,
            shibIdpUrlMetadata: shibIdpUrlMetadata,
            wsfedIdpUrlMetadata: wsfedIdpUrlMetadata,
            entityID: entityID,
            idpCert: idpCert,
            certificateFingerprint: certificateFingerprint,
            endpointauthorization: endpointauthorization,
            endpointtoken: endpointtoken,
            endpointjwks: endpointjwks,
            configurationopenid: configurationopenid,
            serverName: serverName,
            azureAppClientId: azureAppClientId,
            idpEntityIdWsfed: idpEntityIdWsfed,
            idpSigning1: idpSigning1,
            idpSigning2: idpSigning2,
            idpEndpoint: idpEndpoint
        };

    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = init();
            }
            return instance;
        }
    };

};